import React from 'react';
import styled from 'styled-components';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PendingIcon from '@mui/icons-material/Pending';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';


const tasks = [
  { id: 1, percentage: "75%", color: "#36b73b", icon: <DoneAllIcon style={{ color: "#36b73b", fontSize: 30 }} /> },
  { id: 2, percentage: "50%", color: "#17a1fa", icon: <PendingIcon style={{ color: "#17a1fa", fontSize: 30 }} /> },
  { id: 3, percentage: "15%", color: "#ff941c", icon: <ScheduleIcon style={{ color: "#ff941c", fontSize: 30 }} /> },
  { id: 4, percentage: "30%", color: "#ed0000", icon: <CallMissedOutgoingIcon style={{ color: "#ed0000", fontSize: 30 }} /> },
];

const GroupDataTab = ({ selectedGroup, selectedGroupTypeName, teamMembers, handleTeamMemberClick, getIconByGroupType,handleDeleteGroup,handleEditGroup }) => {
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  <style jsx>
          {`
          .BackButton{ 
          padding: 10px 20px;
 background-color: none;
 width:100px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
          }
        `}</style>

        return (
          <>
            <Team>
              <TeamMembers>
                {teamMembers.map(({ group_id, group_name, group_type, group_type_name, hierarchy_full }) => (
                  <TeamMember
                    key={group_id}
                    onClick={() => handleTeamMemberClick(group_id, group_type, group_name, group_type_name, hierarchy_full)}
                  >
                    {getIconByGroupType(group_type)}
                   
                    <TeamMemberDetails>
                      <h4>{truncateText(capitalizeFirstLetter(group_name), 18)}</h4>
                    </TeamMemberDetails>
                  </TeamMember>
                ))}
              </TeamMembers>
            </Team>
          </>
        );
      };



const Team = styled.section`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;

  h3 {
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
  }
`;

const TeamMembers = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
`;

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer; // Add cursor pointer for better UX
`;

const TeamMemberDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h4 {
    margin-top: 12px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #000;
  }

  p {
    margin-top: 6px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #5d5d5d;
  }
`;

export default GroupDataTab;