
let backendHost;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'codedc.cianlogic.com') {
  backendHost = 'https://codedcapi.cianlogic.com';
} else if(hostname === 'codestage.cianlogic.com') {
  backendHost = 'https://codestageapi.cianlogic.com';
} else if(hostname === 'dcprod.cianlogic.com') {
  backendHost = 'https://dcprodapi.cianlogic.com';
}
else if(hostname === 'localhost') {
  backendHost = 'https://codedcapi.cianlogic.com';
}

export const APIURL = `${backendHost}`;
export const HOSTNAME = hostname;




