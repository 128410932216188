import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
// import FolderView from "../components/FolderView";
import SecondFolderview from "../components/SecondFolderview";
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from "react-router-dom";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PendingIcon from '@mui/icons-material/Pending';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import Typography from '@mui/material/Typography';
import { APIURL } from '../Global';
import User from '../assets/User.png';
import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; 
import logout from '../assets/logout.png';
import MenuIcon from '@mui/icons-material/Menu';

function SecondDashboard() {
    const location = useLocation();
    const token = localStorage.getItem('codeDcUserToken');

    const storedUserName = localStorage.getItem('codeDcUsername');
    const storedUserEmail = localStorage.getItem('codeDcUserEmail');

    const [treeData, setTreeData] = useState([]);
    const [isPopupOpenNew, setIsPopupOpenNew] = useState(false);
    const [selectedParentId, setSelectedParentId] = useState(null);
    const [newEntry, setNewEntry] = useState('');
    const [groupType, setGroupType] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedGroupType, setSelectedGroupType] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [expanded, setExpanded] = useState([]); // Manage expanded state here
    const [isAddUserPopupOpen, setIsAddUserPopupOpen] = useState(false);
    const [newUser, setNewUser] = useState({
      username: '',
      password: '',
      confirm_password: '',
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      group_id: selectedGroupId
    });
    const [addUserError, setAddUserError] = useState('');
const [users, setUsers] = useState([]);
const [selectedUser, setSelectedUser] = useState(null);
const [isUserDetailsPopupOpen, setIsUserDetailsPopupOpen] = useState(false);
const [isProfileEditOpen, setIsProfileEditOpen] = useState(false);

// State for managing profile field values
const [profileFields, setProfileFields] = useState({
  name: '',
  dob: '',
  gender: '',
  email: '',
  phone: '',
  houseName: ''
});
const [isSetPasswordPopupOpen, setIsSetPasswordPopupOpen] = useState(false);
const [newPassword, setNewPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #2456ae;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;

  @media (min-width: 1001px) {
    display: none;
  }
`;

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  

const toggleLeftSidebar = () => {
  setLeftSidebarOpen(!leftSidebarOpen);
};

const toggleRightSidebar = () => {
  setRightSidebarOpen(!rightSidebarOpen);
};



useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${APIURL}/backend/api/sub-group-tree/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${token}`
        },
      });
      const responseJson = await response.json();
      console.log(responseJson);
      const data = responseJson.data;
      console.log(data);
      const keralaData = data[0];
      const updatedTreeData = addNewNode([keralaData]);
      setTreeData(updatedTreeData);
    } catch (error) {
      console.error("Error fetching the tree data:", error);
    }
  };
  fetchData();
}, []);


useEffect(() => {
  if (selectedGroupId) {
    const fetchGroupMembers = async () => {
      try {
        const response = await fetch(`${APIURL}/backend/api/group-members/?group_id=${selectedGroupId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
          },
        });
        const responseJson = await response.json();
        const data = responseJson.data;
        console.log(data);

        setUsers(data); // Assuming the response contains an array of members
      } catch (error) {
        console.error("Error fetching the group members:", error);
      }
    };

    fetchGroupMembers();
  }
}, [selectedGroupId, token]);

useEffect(() => {
  setNewUser(prevUser => ({
      ...prevUser,
      group_id: selectedGroupId
  }));
}, [selectedGroupId]);

const addNewNode = (nodes, parentId = null) => {
  return nodes.map(node => ({
    ...node,
    children: node.children
      ? [...addNewNode(node.children), 
         node.group_type < 5 
             ? { id: `add-${node.id}`, group_type: 0, name: "Add New", group_name: "Add New", isNew: true, parent: node.group_id, parent_type: node.group_type } 
             : null
        ].filter(Boolean) 
      : node.group_type < 5 
          ? [{ id: `add-${node.id}`, group_type: node.group_type, name: "Add New", group_name: "Add New", isNew: true, parent: node.group_id }] 
          : []
  }));
};

const handleNodeSelect = (node) => {

  console.log("nav click")

  if (node.teamMembers) {
    setTeamMembers(node.teamMembers);
    console.log("node.teamMembers");
    console.log(node.teamMembers);
  } else {
      console.log("node.children:", node);
      setSelectedGroup(node.group_name);
      setSelectedGroupId(node.group_id);
      setSelectedGroupType(node.group_type);
      
      if (Array.isArray(node.children)) {

          setTeamMembers(node.children);

      } else {

          setTeamMembers([]);

      }

  }

};


const handleToggle = (event, nodeIds) => {
  setExpanded(nodeIds); // Update expanded state here
};


      
  const handleAddNewClick = (parentId, type) => {
    setSelectedParentId(parentId);
    setGroupType(type);
    setIsPopupOpenNew(true);

    // Add the new node to the tree data
    const newNode = {
        id: `add-${parentId}`, // Use a unique identifier for the new node
        group_type: 0,
        name: "Add New",
        group_name: "Add New",
        isNew: true,
        parent: parentId,
        parent_type: type
    };

    // Find the parent node in the tree data
    const updatedTreeData = treeData.map(node => {
      if (node.id === parentId) {
          // Append the new node to the parent's children
          return {
              ...node,
              children: node.children ? [...node.children, newNode] : [newNode]
          };
      }
      return node;
  });

  // Update the tree data state with the new node
  setTreeData(updatedTreeData);
};

    const handleAdd = async () => {
        // API call to add a new group
        const newGroup = {
            name: newEntry,
            group_type: groupType + 1, // Increment group_type by 1
            parent: selectedParentId,
        };
  
        try {
            const response = await fetch(`${APIURL}/backend/api/group/`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
              },
                body: JSON.stringify(newGroup),
            });
  
            if (response.ok) {
                const responseJson = await response.json();
                const data = responseJson.data;
                setResponseMessage(data.message);
                console.log('New group added:', data);
        
                // Optionally refresh the tree data
                try {
                  const response = await fetch(`${APIURL}/backend/api/sub-group-tree/`, {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": `Token ${token}`
                    },
                  });
                  const responseJson = await response.json();
                  const data = responseJson.data;
                  const keralaData = data[0];
                  const updatedTreeData = addNewNode([keralaData]);
                  setTreeData(updatedTreeData);
                  setIsPopupOpenNew(false);
                  console.log(updatedTreeData);
                } catch (error) {
                  console.error("Error fetching the tree data:", error);
                }
            } else {
                setResponseMessage('Failed to add new group: ' + response.statusText);
            }
            } catch (error) {
                setResponseMessage('Error adding new group: ' + error.message);
            }
    };


    const handleNameChange = (event) => {
        setNewEntry(event.target.value);
    };

    const handlePopupClose = () => {
        setIsPopupOpenNew(false);
        setSelectedParentId(null);
        setNewEntry(''); // Reset name input
        setResponseMessage(''); // Reset response message
    };
   
   
    const handleAddUserInputChange = (event) => {
      const { name, value } = event.target;
      setNewUser((prevUser) => ({
        ...prevUser,
        [name]: value,
        ...(name === 'password' && { confirm_password: value }) // Auto set confirm_password
      }));
    };
  
    const handleAddUser = async () => {
      const url = `${APIURL}/backend/api/add-user/`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(newUser)
      };
    
      try {
        const response = await fetch(url, options);
        const data = await response.json();
        console.log(data);
    
        if (response.ok) {
          if(data.status=="error"){
            setAddUserError(data.message); // Set the error message from the API
          }else{
            // Add the new user to the users list
            setUsers((prevUsers) => [...prevUsers, data]);
            setNewUser({
              username: '',
              password: '',
              confirm_password: '',
              first_name: '',
              last_name: '',
              email: '',
              mobile: '',
              group_id: selectedGroupId
            });
            setIsAddUserPopupOpen(false);
          }

        } else {
          console.error('Failed to add user:', data);
        }
      } catch (error) {
        console.error('Error adding user:', error);
      }
    };


    const handleMoreVertIconClick = (user) => {
      setSelectedUser(user);
      setIsUserDetailsPopupOpen(true);
    };
    const handleUserDetailsPopupClose = () => {
      setIsUserDetailsPopupOpen(false);
      setSelectedUser(null);
    };
    const handleProfileEditOpen = () => {
      setIsProfileEditOpen(true);
      // Populate profile fields with existing data
      setProfileFields({
        name: selectedUser.name,
        dob: selectedUser.dob,
        gender: selectedUser.gender,
        email: selectedUser.email,
        phone: selectedUser.phone,
        houseName: selectedUser.houseName
      });
    };
  
    // Function to handle closing profile edit pop-up
    const handleProfileEditClose = () => {
      setIsProfileEditOpen(false);
      // Clear profile field values
      setProfileFields({
        name: '',
        dob: '',
        gender: '',
        email: '',
        phone: '',
        houseName: ''
      });
    };
    const handleSave = () => {
     //save
      setIsProfileEditOpen(false);
    };
  
    const handleCancel = () => {
      // cancel
      setIsProfileEditOpen(false);
    };
    // Function to handle changes in profile fields
    const handleProfileFieldChange = (event) => {
      const { name, value } = event.target;
      setProfileFields((prevFields) => ({
        ...prevFields,
        [name]: value
      }));
    };
    const profileEditPopup = (
      <Dialog open={isProfileEditOpen} onClose={handleProfileEditClose}>
        <div className="dialog-header">
          <DialogTitle className="dialog-title">Edit Profile </DialogTitle>
          
        </div>
        <DialogContent className="inputbox">
          
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Name</Typography>
              <TextField
                name="name"
                type="text"
                fullWidth
                variant="outlined"
                value={profileFields.name}
                onChange={handleProfileFieldChange}
                className="form-field"
              />
            </div>
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Date of Birth</Typography>
              <TextField
                name="dob"
                type="date"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={profileFields.dob}
                onChange={handleProfileFieldChange}
                className="form-field"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Gender</Typography>
              <div className="box">
                <div className="radio-buttons">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={profileFields.gender === 'male'}
                      onChange={handleProfileFieldChange}
                      className="radio-input"
                    />
                    Male
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={profileFields.gender === 'female'}
                      onChange={handleProfileFieldChange}
                      className="radio-input"
                    />
                    Female
                  </label>
                </div>
              </div>
            </div>
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Email</Typography>
              <TextField
                name="email"
                type="email"
                fullWidth
                variant="outlined"
                value={profileFields.email}
                onChange={handleProfileFieldChange}
                className="form-field"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">Phone</Typography>
              <TextField
                name="phone"
                type="text"
                fullWidth
                variant="outlined"
                value={profileFields.phone}
                onChange={handleProfileFieldChange}
                className="form-field"
              />
            </div>
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">House Name</Typography>
              <TextField
                name="houseName"
                type="text"
                fullWidth
                variant="outlined"
                value={profileFields.houseName}
                onChange={handleProfileFieldChange}
                className="form-field"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field-container">
              <Typography variant="body1" className="field-label">User Type</Typography>
              <div className="box">
                <div className="radio-buttons">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="userType"
                      value="admin"
                      checked={profileFields.userType === 'admin'}
                      onChange={handleProfileFieldChange}
                    />
                    Admin
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="userType"
                      value="viewer"
                      checked={profileFields.userType === 'viewer'}
                      onChange={handleProfileFieldChange}
                    />
                    Viewer
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="userType"
                      value="editor"
                      checked={profileFields.userType === 'editor'}
                      onChange={handleProfileFieldChange}
                    />
                    Editor
                  </label>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog-actions">
  <Button onClick={handleSave} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px', marginRight: '10px' }}>
    Save
  </Button>
  <Button onClick={handleCancel} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px' }}>
    Cancel
  </Button>
</DialogActions>

      </Dialog>
    
    );
    

  const handleTeamMemberClick = async (memberId,group_type,group_name) => {
    console.log("memeberId: ",memberId);
    console.log("group_type: ",group_type);
    if(group_type == 0){
      handleAddNewClick(selectedGroupId,selectedGroupType)

    }else{
      try {
          const response = await fetch(`${APIURL}/backend/api/group-children/?group_id=${memberId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
          },
        });
        const responseJson = await response.json();
        const data = responseJson.data;
        console.log(data);    
        if (group_type < 5) {
          data.push({
            group_id: `add-${memberId}`,
            group_type: 0,
            name: "Add New",
            group_name: "Add New",
            isNew: true,
            parent: memberId,
            parent_type: group_type
          });
        }
        console.log(data);    

        setTeamMembers(data); // Assuming the response contains an array of child members

        setSelectedGroup(group_name);
        setSelectedGroupType(group_type);
        setSelectedGroupId(memberId);
  
      } catch (error) {
        console.error("Error fetching the child members:", error);
      }
    }

  };
  
const handleSetPasswordOpen = () => {
  setIsSetPasswordPopupOpen(true);
};


const handleSetPasswordClose = () => {
  setIsSetPasswordPopupOpen(false);
};


const handleNewPasswordChange = (event) => {
  setNewPassword(event.target.value);
};
// Function to handle saving the new password
const handleSavePassword = () => {
  //Save password 
  console.log('New password:', newPassword);


  setIsSetPasswordPopupOpen(false);
};
const handleConfirmPasswordChange = (event) => {
  setConfirmPassword(event.target.value);
};

const setPasswordPopup = (
  <Dialog open={isSetPasswordPopupOpen} onClose={() => setIsSetPasswordPopupOpen(false)}>
    <div className="dialog-header">
      <DialogTitle className="dialog-title">Set Password</DialogTitle>
      <CloseIcon className="pop-close-icon" onClick={() => setIsSetPasswordPopupOpen(false)} />
    </div>
    <DialogContent className="inputbox">
      <div className="form-field-container">
        <Typography variant="body1" className="field-label">New Password</Typography>
        <TextField
          name="newPassword"
          type="password"
          fullWidth
          variant="outlined"
          value={newPassword}
          onChange={handleNewPasswordChange}
          className="form-field"
        />
      </div>
      <div className="form-field-container">
        <Typography variant="body1" className="field-label">Confirm Password</Typography>
        <TextField
          name="confirmPassword"
          type="password"
          fullWidth
          variant="outlined"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          className="form-field"
        />
      </div>
    </DialogContent>
    <DialogActions className="dialog-actions">
      <Button onClick={handleSavePassword} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px', marginRight: '10px' }}>
        Save
      </Button>
      <Button onClick={handleSetPasswordClose} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '100px' }}>
        Cancel
      </Button>
    </DialogActions>
    <style jsx>{`
      .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .dialog-title {
        text-align: center;
        flex-grow: 1;
        color: #035391;
        font-weight: 500;
        margin-right:90px;
      }
      .pop-close-icon {
        cursor: pointer;
        margin-right: 15px;
      }
      .inputbox {
        padding: 16px;
      }
      .dialog-actions {
        justify-content: center;
        background-color: white;
        border-radius: 45px;
        width: 220px; /* Adjust width as needed */
        margin: 0 auto; /* Center horizontally */
        margin-bottom: 20px;
      }
      .form-field-container {
        margin-bottom: 16px;
      }
      .field-label {
        margin-bottom: 8px;
      }
      .form-field {
        width: 100%;
      }
    `}</style>
  </Dialog>
);
const handleLogout = () => {
  // Clear user session data
  localStorage.removeItem('userToken');
  
  window.location.href = '/';
};
const LogoutButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right:10px;
  @media (max-width: 1000px) {
      width: 20px;
      height:20px;
      }
  
`;
  const tasks = [
    { id: 1, percentage: "75%", color: "#36b73b", icon: <DoneAllIcon style={{ color: "#36b73b", fontSize: 30 }} /> },
    { id: 2, percentage: "50%", color: "#17a1fa", icon: <PendingIcon style={{ color: "#17a1fa", fontSize: 30 }} /> },
    { id: 3, percentage: "15%", color: "#ff941c", icon: <ScheduleIcon style={{ color: "#ff941c", fontSize: 30 }} /> },
    { id: 4, percentage: "30%", color: "#ed0000", icon: <CallMissedOutgoingIcon style={{ color: "#ed0000", fontSize: 30 }} /> },
  ];

  const getIconByGroupType = (groupType) => {
    const iconConfig = {
      0: { Icon: AddIcon, color: '#324452' },
      3: { Icon: FolderIcon, color: '#FFD580' },
      4: { Icon: FolderIcon, color: '#65a765' },
      5: { Icon: FolderIcon, color: '#87CEEB' },
    };
  
    const { Icon, color } = iconConfig[groupType] || { Icon: FolderIcon, color: '#FF941C73' };
  
    return <Icon style={{ fontSize: 120, color: color }} />;
  };

  return (
    <Container>
     <MobileHeader>
  <ToggleButton left onClick={toggleLeftSidebar}>
    {leftSidebarOpen ? <CloseIcon /> : <MenuIcon />}
  </ToggleButton>
  <HeaderLogo>
    <img src="/images/company-logo.png" alt="Company Logo" />
    <HeaderTitle>CODEDC</HeaderTitle>
  </HeaderLogo>
  <ToggleButton right onClick={toggleRightSidebar}>
    {rightSidebarOpen ? <CloseIcon /> : <PersonIcon />}
  </ToggleButton>
</MobileHeader>
      <SidebarContainer open={leftSidebarOpen}>
        <SidebarSection>
          <CompanyLogo>
            <img src="/images/company-logo.png" alt="Company Logo" />
            <CompanyText>CODEDC</CompanyText>
          </CompanyLogo>
          <UserManagement>User Management</UserManagement>
        </SidebarSection>
        <SecondFolderview 
            treeData={treeData} 
            handleAddNewClick={handleAddNewClick} 
            onNodeSelect={handleNodeSelect} 
            expanded={expanded} 
            onToggle={handleToggle} 
        />
        {/* <FolderView treeData={treeData} handleAddNewClick={handleAddNewClick} />     */}
      </SidebarContainer>
      <MainContent 
        leftOpen={leftSidebarOpen}
        rightOpen={rightSidebarOpen}
      >
  <Header>
    <SearchBar>
      <input type="search" placeholder="Search..." aria-label="Search" />
    </SearchBar>
    <UserInfo>
      <UserDetails>
        <h2>Welcome Back!</h2>
        <p>{storedUserName}</p>
        <p>{storedUserEmail}</p>
      </UserDetails>
      <LogoutButton onClick={handleLogout}>
        <img src= {logout}  alt="Logout" style={{ cursor: 'pointer', width: '35px', height: '40px' ,marginLeft: '20px' , marginTop: '-12px'}} />
      </LogoutButton>
    </UserInfo>
  </Header>
  <Analytics>
    <TaskTitle>Statistical Analytics</TaskTitle>
    <TaskSection>
      {tasks.map(({ id, percentage, color, icon }) => (
        <TaskCard key={id}>
          <TaskIconWrapper>
            {icon}
          </TaskIconWrapper>
          <TaskBar>
            <TaskBarInner style={{ backgroundColor: color, width: percentage }} />
          </TaskBar>
          <TaskPercentage>{percentage}</TaskPercentage>
        </TaskCard>
      ))}
    </TaskSection>
  </Analytics>
  <Team>
    <h3>{selectedGroup}</h3>
    <TeamMembers>
    {teamMembers.map(({ group_id, group_name, email, group_type }) => (
      <TeamMember key={group_id} onClick={() => handleTeamMemberClick(group_id, group_type, group_name)}>
      {getIconByGroupType(group_type)}
      <TeamMemberDetails>
        <h4>{group_name}</h4>
      </TeamMemberDetails>
    </TeamMember>
      ))}
    </TeamMembers>
  </Team>
</MainContent>

      <RightSidebar open={rightSidebarOpen}>
  <SidebarHeader>
    
      
        <img src={User} alt="users" className="image" />
      
      <div className="dash-member">
        <h4>Members</h4>
      </div>
      <div className="add-member">
        <AddMember onClick={() => {
          setIsAddUserPopupOpen(true);
          setAddUserError('');

        }}>
          <p>Add User &nbsp; <b>+</b> </p>
        </AddMember>
      </div>
    
  </SidebarHeader>

  <div className="users-list">
    {users.map((user, index) => (
      <div key={index} className="user-card">
        <div className="options-icon" onClick={() => handleMoreVertIconClick(user)}>
          <MoreVertIcon className="icon" />
        </div>
        <div className="user-avatar">
          <div className="avatar-circle">
            <PersonIcon className="user-icon" />
            <FiberManualRecordIcon className="online-icon" style={{ fontSize: 18 }} />
          </div>
        </div>
        <div className="user-details">
          <Typography variant="body2" className="user-name">{user.username}</Typography>
          <Typography variant="body2" className="user-email">{user.email}</Typography>
        </div>
      </div>
    ))}
  </div>

  <Dialog open={isAddUserPopupOpen} onClose={() => setIsAddUserPopupOpen(false)}>
  <div className="dialog-header">
    <DialogTitle className="dialog-title">Add User Details</DialogTitle>
    <CloseIcon className="close-icon" onClick={() => setIsAddUserPopupOpen(false)} />
  </div>
  <DialogContent className="inputbox">
    {/* Add User Form */}

    <div className="form-row">
      <div className="form-field-container">
        <Typography variant="body1" className="field-label">Username</Typography>
        <TextField
          name="username"
          type="text"
          fullWidth
          variant="outlined"
          value={newUser.username}
          onChange={handleAddUserInputChange}
          className="form-field"
        />
      </div>
      <div className="form-field-container">
        <Typography variant="body1" className="field-label">New Password</Typography>
        <TextField
          name="password"
          type="password"
          fullWidth
          variant="outlined"
          value={newUser.password}
          onChange={handleAddUserInputChange}
          className="form-field"
        />
      </div>
    </div>
    <div className="form-row">     
      <div className="form-field-container">
        <Typography variant="body1" className="field-label">First Name</Typography>
        <TextField
          name="first_name"
          type="text"
          fullWidth
          variant="outlined"
          value={newUser.first_name}
          onChange={handleAddUserInputChange}
          className="form-field"
        />
      </div>
      <div className="form-field-container">
        <Typography variant="body1" className="field-label">Last Name</Typography>
        <TextField
          name="last_name"
          type="text"
          fullWidth
          variant="outlined"
          value={newUser.last_name}
          onChange={handleAddUserInputChange}
          className="form-field"
        />
      </div>
    </div>
    <div className="form-row">

      <div className="form-field-container">
        <Typography variant="body1" className="field-label">Email</Typography>
        <TextField
          name="email"
          type="email"
          fullWidth
          variant="outlined"
          value={newUser.email}
          onChange={handleAddUserInputChange}
          className="form-field"
        />
      </div>
    </div>
    <div className="form-row">
      <div className="form-field-container">
        <Typography variant="body1" className="field-label">Mobile</Typography>
        <TextField
          name="mobile"
          type="text"
          fullWidth
          variant="outlined"
          value={newUser.mobile}
          onChange={handleAddUserInputChange}
          className="form-field"
        />
      </div>
      <div className="form-field-container">
        <Typography variant="body1" className="field-label">Group ID</Typography>
        <TextField
          name="group_id"
          type="text"
          fullWidth
          variant="outlined"
          value={selectedGroupId}
          onChange={handleAddUserInputChange}
          className="form-field"
        />
      </div>
    </div>
  </DialogContent>
  <DialogActions className="dialog-actions">
  <div className="form-field-container">
  {addUserError && (
      <div className="form-field-container">
      <Typography variant="body2" color="error" align="center">
        {addUserError}
      </Typography>
      </div>
    )}
    <Button onClick={handleAddUser} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Submit Data
    </Button>
</div>
  </DialogActions>
</Dialog>
  {/* Profile edit pop-up */}
  {profileEditPopup}
  {setPasswordPopup}
  <Dialog open={isUserDetailsPopupOpen} onClose={handleUserDetailsPopupClose}>
    <div className="dialog-header">
      <CloseIcon className="pop-close-icon" onClick={handleUserDetailsPopupClose} />
    </div>
    <DialogContent className="inputbox-container">
      <div className="user-avatar-container">
        <div className="avatar-circle-large">
          <PersonIcon className="user-icon-large" />
        </div>
      </div>
      <div className="user-details-container">
        <Typography variant="h6" className="user-name">{selectedUser?.name}</Typography>
        <Typography variant="body1" className="user-email">{selectedUser?.email}</Typography>
      </div>
      <div className="button-container">
        <Button onClick={handleProfileEditOpen}>Profile Edit</Button>
        <Button onClick={() => setIsSetPasswordPopupOpen(true)}>Set Password</Button>
        <Button onClick={() => {/* Handle Delete */}}>Delete</Button>
      </div>
    </DialogContent>
  </Dialog>

  <style jsx>{`
    // .header-content {
    //   display: flex;
    //   align-items: center;
    //   margin-left:-30px;
    //   justify-content: space-between;
    //   width: 100%;
    // }
    .dash-member {
      flex: 1;
      text-align: center;
      margin-left:-100px;
      margin-bottom:50px;
    }
    // .image-container {
    //   width: 200px;
      
    //   height: 40px;
    // }
    .image {
      width: 180px;
      height: 40px;
      margin-top:55px;
      margin-left:-100px;
    }
    .add-member {
      display: flex;
      align-items: center;
    }
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dialog-title {
      text-align: center;
      margin-left: 130px;
      color: #035391;
      font-weight: 500;
    }
    .close-icon {
      cursor: pointer;
      margin-right: 15px;
    }
    .inputbox {
      padding: 16px;
    }
    .dialog-actions {
      justify-content: center;
      background-color: white;
      width: 200px;
      margin-left: 120px;
      margin-bottom: 20px;
      border-radius: 45px;
    }
    .form-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 16px;
    }
    .form-field-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    .field-label {
      margin-bottom: 8px;
    }
    .form-field {
      width: 95%;
    }
    .box {
      border: 1px solid #ccc;
      padding: 12px;
      border-radius: 4px;
    }
    .radio-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .radio-label {
      margin-right: 16px;
    }
    .radio-input {
      margin-right: 8px;
    }
    .users-list {
      margin-top: 16px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .user-card {
      position: relative;
      display: flex;
      align-items: center;
      background-color: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 8px;
      margin-bottom: 8px;
    }
    .options-icon {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }
    .icon {
      font-size: 18px;
    }
    .user-avatar {
      margin-right: 8px;
    }
    .avatar-circle {
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #ADD8E6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .user-icon {
      color: #555;
    }
    .online-icon {
      position: absolute;
      top: 0px;
      right: -2px;
      color: green;
    }
    .user-details {
      flex: 1;
    }
    .user-name,
    .user-email {
      font-size: 14px;
    }
    .user-name {
      margin-top: 10px;
    }
    .user-email {
      margin-bottom: 10px;
    }
    .inputbox-container {
      padding: 16px;
      width: 300px; /* Adjust the width as needed */
    }
    .pop-close-icon {
      cursor: pointer;
      margin-left: auto;
      margin-right: 15px;
      margin-top: 10px;
    }
    .user-avatar-container {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
    }
    .avatar-circle-large {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .user-icon-large {
      font-size: 64px;
      color: #555;
    }
    .user-details-container {
      text-align: center;
      margin-bottom: 16px;
    }
    .user-name,
    .user-email {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .button-container {
      display: flex;
      flex-direction: column;
    }
    .button-container button {
      margin-right: auto; /* Push buttons to the left */
    }
      @media (max-width: 1550px) {
       .image {
      width: 140px;
      height: 30px;
      margin-top:55px;
      margin-left:-100px;
    }
      }
      @media (max-width: 1250px) {
       .image {
      width: 100px;
      height: 27px;
      margin-top:55px;
      margin-left:-100px;
    }
       .user-name,
    .user-email {
      font-size: 12px;
    }
      }
    @media (max-width: 1100px) {
       
     .image {
      width: 80px;
      height: 27px;
      margin-top:55px;
      margin-left:-100px;
    
    }
      }
    // @media (max-width: 999px) {
    //    .image {
    //   display:none;
    // }
      }
  `}</style>
</RightSidebar>

      
      <Dialog open={isPopupOpenNew} onClose={handlePopupClose}>
        <DialogTitle>Add New Group</DialogTitle>
        <DialogContent>
            {responseMessage ? (
            <Typography variant="body1">{responseMessage}</Typography>
            ) : (
            <TextField
                autoFocus
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={newEntry}
                onChange={handleNameChange}
            />
            )}
        </DialogContent>
        <DialogActions>
            {responseMessage ? (
            <Button onClick={handlePopupClose}>Close</Button>
            ) : (
            <>
                <Button onClick={handlePopupClose}>Cancel</Button>
                <Button onClick={handleAdd}>Add</Button>
            </>
            )}
        </DialogActions>
        </Dialog>
        
    </Container>
  );
}

const SidebarHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E8F1FE;
  text-align: center; /* Center align text horizontally */
  margin-left: 110px;
  color: #0476D0;
  font-weight:lighter;
  font-size:20px;
  margin-bottom:-10px;

  
`;

const AddMember = styled.button`
  position: absolute;
  right: 15px;
  top: 75px;
  display: flex;
  align-items: center;
  background-color: #035391;
  border-radius: 40px;
  border: none;
  padding: 8px 20px;
  color: #fff;
  font-size: 16px;
  

  p {
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #fff;
    margin: 0;
  }

  svg {
    font-size: 20px;
  }
@media (max-width: 1450px) {
padding: 4px 14px;
top: 87px;
}
@media (max-width: 1250px) {
padding: 3px 10px;
top: 90px;
}
@media (max-width: 1100px) {
padding: 2px 9px;
top: 90px;
}
`;



const Container = styled.div`
  background-color: #fff;
  display: flex;
  position: relative;
`;

const MobileHeader = styled.header`
  display: none;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

const HeaderTitle = styled.h1`
  color: #2456ae;
  margin: 0;
  font-size: 18px;
`;
const HeaderLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    margin-right:20px;
  }
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  width: 59%;
  padding: 37px 20px;
  transition: width 0.3s;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: ${props => props.leftOpen ? '250px' : '0'};
    margin-right: ${props => props.rightOpen ? '250px' : '0'};
  }
`;

const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  width: 18%;
  height:100vh;
  background-color: rgba(24, 119, 242, 0.02);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 35px 20px 80px;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 1000px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 200px;
    height: 100%;
    z-index: 999;
    transform: ${props => props.open ? 'translateX(0)' : 'translateX(-100%)'};
  }
     @media (max-width: 1000px) {
   margin-top: 50px;
  }
`;
const RightSidebar = styled.aside`
  display: flex;
  flex-direction: column;
  width: 23%;
  transition: transform 0.3s ease-in-out;
  background: #e8f1fe;
  @media (max-width: 1000px) {
    position: fixed;
    right: 0;
    top: 0;
    width: 250px;
    height: 100%;
    z-index: 999;
    transform: ${props => props.open ? 'translateX(0)' : 'translateX(100%)'};
  }
     @media (max-width: 1000px) {
   margin-top: 50px;
  }
`;

const SidebarSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const CompanyLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    margin-left:20px;
  }
     @media (max-width: 1000px) {
    display: none;
  }
`;

const CompanyText = styled.span`
  font-family: Inter, sans-serif;
  flex-grow: 1;
  font-weight: 700;
  color: #2456ae;
  margin-left: -45px;
  @media (max-width: 1100px){
  margin-left:-15px;
  }
   @media (max-width: 1000px) {
    display: none;
  }
`;

const UserManagement = styled.h3`
  margin-top: 40px;
  text-align: center;
  padding-left:25px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size:17px;
`;

const TaskSection = styled.section`
  display: flex;
  width: 100%;
  gap: 0px;
`;

const TaskTitle = styled.h3`
  text-align: left;
  margin-left: 5px;
`;

const TaskCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 5px;
`;

const TaskIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;

  img {
    width: 30px;
  }
`;

const TaskPercentage = styled.span`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-top: 10px;
`;

const TaskBar = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin-top: 5px;
`;


const TaskBarInner = styled.div`
  height: 100%;
  border-radius: 5px;
`;


const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 1000px) {
   margin-top: 50px;
  }
`;

const SearchBar = styled.div`
  input {
    padding: 12px 26px;
    border-radius: 15px;
    border: 1px solid rgba(217, 217, 217, 1);
    font-size: 14px;
    font-weight: 500;
    color: #afafaf;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 1);
  }
`;

const UserDetails = styled.div`
  margin-left: 10px;

  h2 {
    font-size: 10px;
    font-weight: 400;
    color: #5d5d5d;
  }

  p {
    margin-top: 6px;
    font-size: 12px;
  }
`;

const Analytics = styled.section`
  background-color: rgba(76, 175, 80, 0.15);
  padding: 15px;
  border: 1px solid rgba(175, 175, 175, 1);
  border-radius: 10px;
  margin-bottom: 20px;

  h3 {
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #5d5d5d;
    margin-bottom: 20px;
  }
`;

const Team = styled.section`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;

  h3 {
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
  }
`;

const TeamMembers = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
`;

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer; // Add cursor pointer for better UX
`;

const TeamMemberDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h4 {
    margin-top: 12px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #000;
  }

  p {
    margin-top: 6px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #5d5d5d;
  }
`;
export default SecondDashboard;

