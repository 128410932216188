import React from 'react';
import styled from 'styled-components';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PendingIcon from '@mui/icons-material/Pending';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';


const tasks = [
  { id: 1, percentage: "75%", color: "#36b73b", icon: <DoneAllIcon style={{ color: "#36b73b", fontSize: 30 }} /> },
  { id: 2, percentage: "50%", color: "#17a1fa", icon: <PendingIcon style={{ color: "#17a1fa", fontSize: 30 }} /> },
  { id: 3, percentage: "15%", color: "#ff941c", icon: <ScheduleIcon style={{ color: "#ff941c", fontSize: 30 }} /> },
  { id: 4, percentage: "30%", color: "#ed0000", icon: <CallMissedOutgoingIcon style={{ color: "#ed0000", fontSize: 30 }} /> },
];

const ReportsTab = ({ selectedGroup, teamMembers, handleTeamMemberClick, getIconByGroupType }) => {
  return (
    <>
      <Analytics>
        <TaskTitle>Statistical Analytics</TaskTitle>
        <TaskSection>
          {tasks.map(({ id, percentage, color, icon }) => (
            <TaskCard key={id}>
              <TaskIconWrapper>
                {icon}
              </TaskIconWrapper>
              <TaskBar>
                <TaskBarInner style={{ backgroundColor: color, width: percentage }} />
              </TaskBar>
              <TaskPercentage>{percentage}</TaskPercentage>
            </TaskCard>
          ))}
        </TaskSection>
      </Analytics>
      
      
    </>
  );
};

const Analytics = styled.section`
  background-color: rgba(76, 175, 80, 0.15);
  padding: 15px;
  border: 1px solid rgba(175, 175, 175, 1);
  border-radius: 10px;
  margin-bottom: 20px;

  h3 {
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #5d5d5d;
    margin-bottom: 20px;
  }
`;

const TaskSection = styled.section`
  display: flex;
  width: 100%;
  gap: 0px;
`;

const TaskTitle = styled.h3`
  text-align: left;
  margin-left: 5px;
`;

const TaskCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 5px;
`;

const TaskIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;

  img {
    width: 30px;
  }
`;

const TaskPercentage = styled.span`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-top: 10px;
`;

const TaskBar = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin-top: 5px;
`;

const TaskBarInner = styled.div`
  height: 100%;
  border-radius: 5px;
`;

const Team = styled.section`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;

  h3 {
    font-family: Inter, sans-serif;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
  }
`;




export default ReportsTab;
