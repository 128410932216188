import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import { styled, useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Folder from '@mui/icons-material/Folder';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddIcon from '@mui/icons-material/Add';
import Circle from '@mui/icons-material/Circle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Root,
  TreeItem2GroupTransition,
} from '@mui/x-tree-view/TreeItem2';
import { unstable_useTreeItem2 as useTreeItem } from '@mui/x-tree-view/useTreeItem2';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';

const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  marginBottom: theme.spacing(0.3),
  color: theme.palette.text.secondary,
  borderRadius: theme.spacing(2),
  paddingRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  '&.expanded': {
    fontWeight: theme.typography.fontWeightRegular,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.focused, &.selected, &.selected.focused': {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    color: 'var(--tree-view-color)',
  },
}));

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(({ theme }) => ({
  marginLeft: 0,
  [`& .content`]: {
    paddingLeft: theme.spacing(2),
  },
}));

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const theme = useTheme();
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    bgColor,
    color,
    iconColor,
    FolderIcon,
    labelIcon: LabelIcon,
    labelInfo,
    colorForDarkMode,
    bgColorForDarkMode,
    ...other
  } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });

  const style = {
    '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
    '--tree-view-bg-color': theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    paddingLeft: theme.spacing(1),
  };

  return (
    <TreeItem2Provider itemId={itemId}>
      <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx('content', {
              expanded: status.expanded,
              selected: status.selected,
              focused: status.focused,
            }),
          })}
        >
          <CustomTreeItemIconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
            <Box component={LabelIcon} color={iconColor} />
          </CustomTreeItemIconContainer>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              p: 0.5,
              pr: 0,
            }}
          >
            <Box component={FolderIcon} color="inherit" sx={{ mr: 1 }} />
            <Typography
              {...getLabelProps({
                variant: 'body2',
                sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
              })}
            >
              {label}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </Box>
        </CustomTreeItemContent>
        {children && (
          <CustomTreeItemGroupTransition {...getGroupTransitionProps()}>
            {children}
          </CustomTreeItemGroupTransition>
        )}
      </CustomTreeItemRoot>
    </TreeItem2Provider>
  );
});

function EndIcon() {
  return <div style={{ width: 24 }} />;
}

const categoryMap = {
  1: { icon: Circle, color: '#1a73e8', bgColor: '#e8f0fe', iconColor: '#FF0000', colorForDarkMode: '#B8E7FB', bgColorForDarkMode: alpha('#00b4ff', 0.2) },
  2: { icon: Circle, color: '#1a73e8', bgColor: '#e8f0fe', iconColor: '#FF0000', colorForDarkMode: '#B8E7FB', bgColorForDarkMode: alpha('#00b4ff', 0.2) },
  3: { icon: Circle, color: '#e3742f', bgColor: '#fcefe3', iconColor: '#FF941C', colorForDarkMode: '#FFE2B7', bgColorForDarkMode: alpha('#ff8f00', 0.2) },
  4: { icon: Circle, color: '#a250f5', bgColor: '#f3e8fd', iconColor: '#2FA533', colorForDarkMode: '#D9B8FB', bgColorForDarkMode: alpha('#9035ff', 0.15) },
  5: { icon: Circle, color: '#1a73e8', bgColor: '#e8f0fe', iconColor: '#0000FF', colorForDarkMode: '#B8E7FB', bgColorForDarkMode: alpha('#00b4ff', 0.2) },
  0: { icon: AddIcon, color: '#3c8039', bgColor: '#e6f4ea', iconColor: '#0066FF', colorForDarkMode: '#CCE8CD', bgColorForDarkMode: alpha('#64ff6a', 0.2) },
};

export default function FolderView({ treeData, handleAddNewClick, onNodeSelect, onToggle }) {
  const [expandedNodes, setExpandedNodes] = useState(() => 
    treeData.map(node => String(node.group_id))
  );

  const handleToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
    if (onToggle) {
      onToggle(event, nodeIds);
    }
  };

  const handleNodeClick = (event, node) => {
    event.stopPropagation();
    event.preventDefault();
    if (node.isNew) {
      handleAddNewClick(node.parent, node.parent_type);
    } else if (node.group_type !== 1) {
      onNodeSelect(node);
    }
  };

  const renderTree = (nodes, parentId = '') => {
    const nodeId = nodes.group_id ? String(nodes.group_id) : `node-${parentId}`;

    const categoryConfig = categoryMap[nodes.group_type] || {};

    return (
      <CustomTreeItem
        key={nodeId}
        id={nodeId}
        itemId={nodeId}
        label={nodes.group_name}
        color={categoryConfig.color}
        bgColor={categoryConfig.bgColor}
        iconColor={categoryConfig.iconColor}
        colorForDarkMode={categoryConfig.colorForDarkMode}
        bgColorForDarkMode={categoryConfig.bgColorForDarkMode}
        labelIcon={categoryConfig.icon}
        FolderIcon={nodes.group_type !== 0 ? Folder : null}
        labelInfo=''
        onClick={(event) => handleNodeClick(event, nodes)}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node, childIndex) => renderTree(node, `${nodeId}-${childIndex}`))
          : null}
      </CustomTreeItem>
    );
  };

  return (
    <SimpleTreeView
      aria-label="folder structure"
      expanded={expandedNodes}
      onNodeToggle={handleToggle}
      slots={{
        expandIcon: ArrowRightIcon,
        collapseIcon: ArrowDropDownIcon,
        endIcon: EndIcon,
      }}
      sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      {treeData.map((data, index) => renderTree(data))}
    </SimpleTreeView>
  );
}