import React, { useState } from "react";
import styled from "styled-components";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import { APIURL } from '../Global';

function LoginComponent() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(""); // Reset error state

      const response = await fetch(`${APIURL}/backend/api/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const responseJson = await response.json();
      console.log(responseJson);
      // console.log(data.data.token);
      if(responseJson.status=='success'){
      // Navigate to OTP page with token





        const data = responseJson.data;
              // console.log(data);

        if(data.group_type == 2){
          navigate('/otp', { state: { username: username } });
        }else{

          if(data.user_status == 1){
            // localStorage.clear();
            localStorage.setItem('codeDcUserToken', data.token);
            localStorage.setItem('codeDcUsername', data.username);
            localStorage.setItem('codeDcUserEmail', data.email);
            localStorage.setItem('codeDcUserMobile', data.mobile);
            localStorage.setItem('codeDcUserRole', data.role);
            localStorage.setItem('codeDcUserId', data.user_id);
            localStorage.setItem('codeDcUserLevel', data.level_name);
            localStorage.setItem('codeDcUserGroupType', data.group_type);
            localStorage.setItem('codeDcUserGroupId', data.group_id);
            navigate('/dashboard');        
          }else{    
            setError("OTP validation failed");
          }

        }



      }else{
          setError(responseJson.message || "Login failed");
      }
    } else {
      const errorData = await response.json();
      setError(errorData.message || "Login failed");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Main>
      <Container>
        <LoginColumn>
          <Form onSubmit={handleSubmit}>
            <Title>Login into your Account</Title>
            <Subtitle>Welcome back! Select method to login:</Subtitle>
            <InputWrapper>
              <IconImg src="/images/email-icon.png" alt="" />
              <Input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <InnerWrapper>
                <img src="/images/lock-icon.png" alt="" />
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InnerWrapper>
              {showPassword ? (
                <VisibilityIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
              ) : (
                <VisibilityOffIcon onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
              )}
            </InputWrapper>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Options>
              <RememberMeWrapper>
                <Checkbox type="checkbox" />
                <Label>Remember me</Label>
              </RememberMeWrapper>
              <ForgotPassword href="#">Forgot password?</ForgotPassword>
            </Options>
            <LoginButton type="submit">Login</LoginButton>
            {/* <CreateAccount>
              Don’t have an account?{" "}
              <CreateAccountLink href="#">Create an account</CreateAccountLink>
            </CreateAccount> */}
          </Form>
        </LoginColumn>
        <ImageColumn>
          <GraphicWrapper>
            <ImageContainer>
              <GraphicImg
                src="/images/login-page-right.png"
                alt="Decorative graphic"
              />
              <DotWrapper>
                <Dot />
                <Dot />
                <Dot />
              </DotWrapper>
            </ImageContainer>
          </GraphicWrapper>
        </ImageColumn>
      </Container>
    </Main>
  );
}

const Main = styled.main`
  background-color: #fff;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }
`;

const LoginColumn = styled.section`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 50px;
  @media (max-width: 991px) {
    width: 100%;
    padding: 10px;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Title = styled.h1`
  color: #000;
  font: 700 30px "Inter", sans-serif;
  @media (max-width: 991px) {
    text-align: center;
  }
`;

const Subtitle = styled.p`
  color: #000;
  margin-top: 34px;
  font: 400 20px "Inter", sans-serif;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  color: #5d5d5d;
  padding: 25px 38px;
  margin-top: 20px;
  border: 1px solid rgba(93, 93, 93, 1);
  border-radius: 12px;
  @media (max-width: 991px) {
    padding: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
  }
  justify-content: space-between;
`;

const IconImg = styled.img`
  width: 28px;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
  flex-grow: 1;
`;

const Input = styled.input`
  flex-grow: 1;
  border: none;
  background: none;
  outline: none;
  font-family: "Inter", sans-serif;
  font-size: 16px;
`;

const Options = styled.div`
  display: flex;
  margin-top: 28px;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const RememberMeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  color: #5d5d5d;
  flex-grow: 1;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 29px;
  height: 29px;
  border-radius: 5px;
`;

const Label = styled.label`
  font-family: "Inter", sans-serif;
`;

const ForgotPassword = styled.a`
  font-family: "Inter", sans-serif;
  color: #1976d2;
  margin-top: 12px;
  flex-grow: 1;
`;

const LoginButton = styled.button`
  margin-top: 33px;
  padding: 28px 60px;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  color: #fff;
  background-color: #1976d2;
  border: 1px solid rgba(93, 93, 93, 1);
  border-radius: 12px;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const CreateAccount = styled.p`
  margin-top: 33px;
  font-family: "Inter", sans-serif;
  text-align: center;
`;

const CreateAccountLink = styled.a`
  color: #1976d2;
  cursor: pointer;
`;

const ImageColumn = styled.aside`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 991px) {
    display:none;
    width: 100%;
    margin-top: 40px;
  }
`;

const GraphicWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: #065ad7;
  padding: 80px 60px;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 149px;
  width: 508px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const GraphicImg = styled.img`
  width: 100%;
`;

const DotWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 68px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Dot = styled.div`
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 20px;
  text-align: center;
  font-family: "Inter", sans-serif;
`;

export default LoginComponent;
