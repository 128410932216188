import * as React from "react";
import styled from "styled-components";

function LoginComponent() {

  const [codes, setCodes] = React.useState(["", "", "", ""]);

  const handleChange = (index, value) => {
    if (/^[0-9]?$/.test(value)) {
      const newCodes = [...codes];
      newCodes[index] = value;
      setCodes(newCodes);
    }
  };

  return (
    <Main>
      <Container>
        <LoginColumn>
        <VerificationSection>
          <Title>Verify your account</Title>
          <Subtitle>OTP Verification</Subtitle>
          <Info>
            <Instruction>
              Enter the code we sent to{" "}
              <Highlight>7736364304</Highlight>
            </Instruction>
            <Timer>0:45</Timer>
          </Info>
          <OTPForm>
            {codes.map((code, index) => (
              <OTPInput
                key={index}
                type="text"
                maxLength="1"
                inputMode="numeric"
                value={code}
                onChange={(e) => handleChange(index, e.target.value)}
                aria-label={`OTP digit ${index + 1}`}
              />
            ))}
          </OTPForm>
          <ResendInfo>
            <ResendInstruction>If you didn’t receive a code</ResendInstruction>
            <ResendButton type="button">Resend</ResendButton>
          </ResendInfo>
          <SubmitButton type="button">Submit OTP</SubmitButton>
          <Separator>
            -------------------------- OR --------------------------
          </Separator>
          <LoginButton type="button">Login</LoginButton>
        </VerificationSection>
        </LoginColumn>
        <ImageColumn>
          <GraphicWrapper>
            <ImageContainer>
              <GraphicImg src="/images/login-page-right.png" alt="Decorative graphic" />
              <DotWrapper>
                <Dot />
                <Dot />
                <Dot />
              </DotWrapper>
            </ImageContainer>
          </GraphicWrapper>
        </ImageColumn>
      </Container>
    </Main>
  );
}

const Main = styled.main`
  background-color: #fff;
  text-align: left;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }
`;

const LoginColumn = styled.section`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 50px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Title = styled.h1`
  color: #000;
  font: 700 30px "Inter", sans-serif;
  @media (max-width: 991px) {
    text-align: center;
  }
`;

const Subtitle = styled.p`
  color: #000;
  margin-top: 5px;
  margin-bottom: 5px;
  font: 400 20px "Inter", sans-serif;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  color: #5d5d5d;
  padding: 25px 38px;
  margin-top: 20px;
  border: 1px solid rgba(93, 93, 93, 1);
  border-radius: 12px;
  background-color: rgba(175, 175, 175, 0.22);
  @media (max-width: 991px) {
    padding: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
  }
  justify-content: space-between;
`;

const IconImg = styled.img`
  width: 28px;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
  flex-grow: 1;
`;

const Input = styled.input`
  flex-grow: 1;
  border: none;
  background: none;
  outline: none;
  font-family: "Inter", sans-serif;
  font-size: 16px;
`;

const ShowPasswordImg = styled.img`
  width: 24px;
  margin-top: 4px;
`;

const Options = styled.div`
  display: flex;
  margin-top: 28px;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const RememberMeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  color: #5d5d5d;
  flex-grow: 1;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 29px;
  height: 29px;
  border-radius: 5px;
`;

const Label = styled.label`
  font-family: "Inter", sans-serif;
`;

const ForgotPassword = styled.a`
  font-family: "Inter", sans-serif;
  color: #1976d2;
  margin-top: 12px;
  flex-grow: 1;
`;

const LoginButton = styled.button`
  margin-top: 33px;
  padding: 28px 60px;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  color: #fff;
  background-color: #1976d2;
  border: 1px solid rgba(93, 93, 93, 1);
  border-radius: 12px;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const CreateAccount = styled.p`
  margin-top: 33px;
  font-family: "Inter", sans-serif;
  text-align: center;
`;

const CreateAccountLink = styled.a`
  color: #1976d2;
  cursor: pointer;
`;

const ImageColumn = styled.aside`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;

const GraphicWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: #065ad7;
  padding: 80px 60px;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 149px;
  width: 508px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const GraphicImg = styled.img`
  width: 100%;
`;

const DotWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 68px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Dot = styled.div`
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
`;

const ContactInput = styled.input`
  font-family: Roboto, sans-serif;
  border: none;
  outline: none;
  flex-grow: 1;
`;

const Icon = styled.img`
  width: 30px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
`;

const SubmitButton = styled.button`
  border: 1px solid rgba(93, 93, 93, 1);
  border-radius: 12px;
  background-color: #1976d2;
  color: #fff;
  padding: 30px 60px;
  font: 500 24px Inter, sans-serif;
  margin-top: 48px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const StyledDiv = styled.div`
  background-color: #fff;
  padding-left: 80px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;


const VerificationSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;



const Info = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 20px;
  font-weight: 400;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Instruction = styled.p`
  color: #6c757d;
  flex-grow: 1;
  flex-basis: auto;
  font: 20px Roboto, sans-serif;
`;

const Highlight = styled.span`
  font-weight: 700;
`;

const Timer = styled.p`
  color: #17a2b8;
  flex-grow: 1;
  flex-basis: auto;
  font: 20px Roboto, sans-serif;
`;

const OTPForm = styled.form`
  display: flex;
  margin-top: 20px;
  gap: 20px;
  justify-content: space-between;
  align-self: center;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

const OTPInput = styled.input`
  width: 69px;
  height: 78px;
  border-radius: 5px;
  background-color: rgba(217, 217, 217, 0.57);
  text-align: center;
  font-size: 24px;
  border: none;
`;

const ResendInfo = styled.div`
  display: flex;
  margin-top: 22px;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const ResendInstruction = styled.p`
  color: #555;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 20px/120% Poppins, sans-serif;
`;

const ResendButton = styled.button`
  color: #17a2b8;
  flex-grow: 1;
  flex-basis: auto;
  font: 500 24px Roboto, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
`;



const Separator = styled.div`
  color: #c2c2c2;
  text-align: center;
  margin-top: 22px;
  font: 400 16px/150% Roboto, sans-serif;
`;


const ImageSection = styled.aside`
  display: flex;
  flex-direction: column;
  width: 57%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;

const ImageWrapper = styled.div`
  background-color: #065ad7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 80px 60px;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 508px;
  aspect-ratio: 1.06;
  object-fit: cover;
  margin-top: 149px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const DotsWrapper = styled.div`
  display: flex;
  margin-top: 68px;
  gap: 10px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;


export default LoginComponent;
