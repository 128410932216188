import * as React from "react";
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { Select, MenuItem } from '@mui/material';
import {FormControlLabel} from '@mui/material';
import { FaEdit, FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import HouseIcon from '@mui/icons-material/House';
import GroupsIcon from '@mui/icons-material/Groups';
import DataTable from 'react-data-table-component';
import { APIURL } from '../Global';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Key } from "@mui/icons-material";

function SurveysTab({ 
  handleNodeSelect, 
  handleTeamMemberClick, 
  selectedGroup, 
  token, 
  selectedGroupId, 
  teamMembers 
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [formTabIndex, setFormTabIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [contacts, setContacts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [expanded, setExpanded] = useState('personal');
  const [newContact, setNewContact] = useState({
    group: selectedGroupId,
    polling_booth_number: '',
    polling_booth_name: '',
    sl_no: '',
    house_no: '',
    house_name: '',
    name: '',
    guardian_name: '',
    guardian_relation: '',
    dob: '',
    age: '',
    gender: 'choose an option',
    epic_no: '',
    education: 'choose an option',
    occupation: '',
    marital_status: 'Single',
    expatriate: 'No',
    vehicles: 'None',
    differently_abled: false,
    chronic_disease: '',
    religion: 'Choose an option',
    caste: '',
    sub_caste: '',
    religious_organizations: '',
    social_organizations: '',
    political_affiliation_party: '',
    political_affiliation_status: '',
    pension: false,
    aadhaar_number: '',
    email: '',
    phone: '',
    eligible_to_vote: 'No'
  });
  const [currentContact, setCurrentContact] = useState(null);
  const initialContactState = {
    group: selectedGroupId,
    polling_booth_number: '',
    polling_booth_name: '',
    sl_no: '',
    house_no: '',
    house_name: '',
    name: '',
    guardian_name: '',
    guardian_relation: '',
    dob: '',
    age: '',
    gender: 'choose an option',
    epic_no: '',
    education: 'choose an option',
    occupation: '',
    marital_status: 'Single',
    expatriate: 'No',
    vehicles: 'None',
    differently_abled: false,
    chronic_disease: '',
    religion: 'Choose an option',
    caste: '',
    sub_caste: '',
    religious_organizations: '',
    social_organizations: '',
    political_affiliation_party: '',
    political_affiliation_status: '',
    pension: false,
    aadhaar_number: '',
    email: '',
    phone: '',
    eligible_to_vote: 'No'
  };

  const openModal = () => {
    setNewContact(initialContactState);
    setFormErrors({}); 
    setModalOpen(true);
  };



  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [verificationData, setVerificationData] = useState({

    house_no: '',
    house_name: ''
  });
  const [formErrors, setFormErrors] = useState({});

  const MARITAL_STATUS_CHOICES = ['Single', 'Married', 'Divorced', 'Widowed'];
  const GENDER_CHOICES = ['choose an option',['M', 'Male'], ['F', 'Female'], ['T', 'Transgender']];
  // const PENSION_CHOICES = [ 'choose an option','Yes', 'No'];
  // const VOTE_CHOICES = [ 'choose an option','Yes', 'No'];
  const RATION_CHOICES = [['choose an option','choose an option'],['APL', 'APL'], ['BPL', 'BPL'], ['AAY', 'AAY'], ['PHH', 'PHH'], ['AY', 'AY']];
  const DIFFERENTLY_ABLED_CHOICES = ['Yes', 'No'];
  const EXPATRIATE_STATUS_CHOICES = ['choose an option', 'No', 'National', 'International'];
  const EDUCATION_CHOICES = ['choose an option','Below 10th', '10th', '12th', 'Graduation', 'Post-Graduation', 'Above Post-Graduation'];
const RELIGION_CHOICES = ['Choose an option','Hinduism', 'Islam', 'Christianity', 'Sikhism', 'Buddhism', 'Jainism', 'Parsi', 'Judaism'];
const YES_NO_CHOICES = [ true, false];
const BOOLEAN_CHOICES = ['No', 'Yes'];
const VEHICLE_CHOICES = ['None','Two Wheeler', 'Four Wheeler', 'Both', 'Others'];
const WATER_SOURCE_CHOICES = ['choose an option','Well', 'Borewell', 'Hand pump', 'Municipality water supply', 'River', 'Pond', 'Other'];
const HOUSE_STATUS_CHOICES = ['choose an option','Own', 'Rented', 'Leased', 'Other'];
// const LAND_CHOICES = ['choose an option','Yes', 'No'];
// const WASTE_MANAGEMENT_CHOICES = ['choose an option','Yes', 'No'];


// const toggleVerification = (contact) => {
//   setVerificationData({
//     individual_id: contact.id,
//     house_no: contact.house_no,
//     house_name: contact.house_name,
//   });
//   setVerificationModalOpen(true);
// };

const isSmallScreen = useMediaQuery('(max-width:650px)');



const toggleVerification = (contact) => {
  if (contact.verified) {
    alert('Already verified');
  } else {
    setVerificationData({
      individual_id: contact.id,
      house_no: contact.house_no,
      house_name: contact.house_name,
    });
    setVerificationModalOpen(true);
  }
};


const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);

};


  // const toggleVerificationOne = (id) => {
  //   setHouseData(prevData => 
  //     prevData.map(row => 
  //       row.id === id ? { ...row, verified: !row.verified } : row
  //     )
  //   );
  // };

const VerifyIcon = styled.span`
cursor: pointer;
`;

  const [houseModalOpen, setHouseModalOpen] = useState(false);
const [newHouseData, setNewHouseData] = useState({

  house_no: '',
  house_name: '',
  family_name: '',
  land: 'No',
  house_status: 'choose an option',
  electricity: false,
  toilet: false,
  road_access: false,
  waste_management: 'No',
  drinking_water_source: 'choose an option',
  ration_card_type: 'APL',
  ration_card_number: ''
});

const initialHouseState = {

  house_no: '',
  house_name: '',
  family_name: '',
  land: 'No',
  house_status: 'choose an option',
  electricity: false,
  toilet: false,
  road_access: false,
  waste_management: 'No',
  drinking_water_source: 'choose an option',
  ration_card_type: 'APL',
  ration_card_number: ''
};

const openHouseModal = () => {
  setNewHouseData(initialHouseState);
  setFormErrors({}); 
  setHouseModalOpen(true);
};

const [houseData, setHouseData] = useState([]);
const [currentHouseData, setCurrentHouseData] = useState(null);
const [editHouseModalOpen, setEditHouseModalOpen] = useState(false);
const [familyData, setFamilyData] = useState([]);
const [newFamilyData, setNewFamilyData] = useState({

  family_name: '',
});

const initialFamilyState = {
 
  family_name: '',
};

const openFamilyModal = () => {
  setNewFamilyData(initialFamilyState);
  setFormErrors({}); 
  setFamilyModalOpen(true);
};

const [currentFamilyData, setCurrentFamilyData] = useState(null);
const [familyModalOpen, setFamilyModalOpen] = useState(false);
const [editFamilyModalOpen, setEditFamilyModalOpen] = useState(false);



const handleAccordionChange = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : false);
};


useEffect(() => {
  if (modalOpen || editModalOpen) {
    setExpanded('personal');
  }
}, [modalOpen,editModalOpen]);


const formatDate = (date) => {
  if (!date) return '';
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};

const displayDate = (date) => {
  if (!date) return '';
  const parts = date.split('-');
  if (parts.length !== 3) return date;
  const [day, month, year] = parts;
  return `${year}-${month}-${day}`;
};


const addRow = async () => {
  const mandatoryFields = ['group', 'name', 'gender', 'house_no', 'house_name', 'polling_booth_number', 'polling_booth_name'];

  const emptyMandatoryFields = mandatoryFields.filter(field => !newContact[field]);

  if (emptyMandatoryFields.length > 0) {
    alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
    return;
  }

  const dataToSend = {
    ...newContact,
    dob: formatDate(newContact.dob),
    eligible_to_vote: Boolean(newContact.eligible_to_vote),
    pension: Boolean(newContact.pension),
    // differently_abled:Boolean(newContact.differently_abled),

  };
  delete dataToSend.age;

  try {
    const response = await fetch(`${APIURL}/data_collection/api/add-individuals/?group_id=${newContact.group}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(dataToSend)
    });

    const data = await response.json();

    if (data.status === 'success') {
      alert(data.message || 'New data added');
      fetchIndividuals(selectedGroupId);
      fetchHouseData(selectedGroupId);
      fetchFamilyData(selectedGroupId);
      setContacts([...contacts, newContact]);
      setModalOpen(false);
      setNewContact(initialContactState);
      try {
        const rawResponse = await fetch(`${APIURL}/data_collection/api/individuals-raw/?group_id=${selectedGroupId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          }
        });

        const rawData = await rawResponse.json();
        if (rawResponse.ok) {
          console.log('Raw Data:', rawData);
          // Handle the raw data as necessary, e.g., updating state or logging
        } else {
          console.error('Failed to fetch raw data:', rawData);
          alert('Failed to fetch raw data');
        }
      } catch (error) {
        console.error('Error fetching raw data:', error);
        alert('An error occurred while fetching raw data');
      }

      setNewContact({
        group: selectedGroupId,
        polling_booth_number: '',
        polling_booth_name: '',
        sl_no: '',
        house_no: '',
        house_name: '',
        name: '',
        guardian_name: '',
        guardian_relation: '',
        dob: '',
        age: '',
        gender: 'choose an option',
        epic_no: '',
        education: 'choose an option',
        occupation: '',
        marital_status: 'Single',
        expatriate: 'No',
        vehicles: 'None',
        differently_abled: false,
        chronic_disease: '',
        religion: 'Choose an option',
        caste: '',
        sub_caste: '',
        religious_organizations: '',
        social_organizations: '',
        political_affiliation_party: '',
        political_affiliation_status: '',
        pension: false,
        aadhaar_number: '',
        email: '',
        phone: '',
        eligible_to_vote: false
      });
    } else {
      alert(data.message || 'Failed to add new data');
    }
  } catch (error) {
    console.error('Error adding new contact:', error);
    alert('An error occurred while adding the contact');
  }
};





const handleVerification = async () => {
  try {
    let addToExisting = true;

    const response = await fetch(`${APIURL}/data_collection/api/verify-individuals/?individual_id=${verificationData.individual_id}&add_to_existing=${addToExisting}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify({
        
          verified: true,
          house_no: verificationData.house_no,
          house_name: verificationData.house_name
        
      })
    });

   

    const data = await response.json();
    if (data.status === 'success') {
   
      alert(data.message);
      fetchIndividuals(selectedGroupId);
       fetchHouseData(selectedGroupId);
      setContacts(contacts.map(contact =>
        contact.id === verificationData.individual_id
          ? { ...contact, verified: true }
          : contact
      ));
      setVerificationModalOpen(false);
    } else {
      alert(data.message || 'Verification failed');
    }
  } catch (error) {
    console.error('Error verifying individual:', error);
    alert('An error occurred while verifying the individual');
  }
};



// const handleVerification = async () => {
//   try {


//     const addToExisting = verificationData.house_no && verificationData.house_name;


//     const response = await fetch(`${APIURL}/data_collection/api/verify-individuals/?individual_id=${verificationData.individual_id}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       },
//       body: JSON.stringify({
//         add_to_existing:  addToExisting,
//         create_new_household: !verificationData. addToExisting,
//         fields: {
//           verified: true , 
//           house_no: verificationData.house_no,
//           house_name: verificationData.house_name
//         }
//       })
//     });

//     const data = await response.json();

//     if (data.status === 'success') {
//       alert(data.message);
//       setContacts(contacts.map(contact =>
//         contact.id === verificationData.individual_id
//           ? { ...contact, verified: false } 
//           : contact
//       ));
//       setVerificationModalOpen(false);
//     } else {
//       alert(data.message || 'Verification failed');
//     }
//   } catch (error) {
//     console.error('Error verifying individual:', error);
//     alert('An error occurred while verifying the individual');
//   }
// };


const fetchIndividuals = async (groupId) => {
  try {
    const response = await fetch(`${APIURL}/data_collection/api/individuals/?group_id=${groupId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
    });
    const data = await response.json();
    if (data.status === "success") {
      setContacts(data.data || []);
    } else {
      setContacts([]);
    }
  } catch (error) {
    console.error("Error fetching individuals:", error);
    setContacts([]);
  }
};


useEffect(() => {
  if (selectedGroupId && typeof selectedGroupId === 'number') {
    fetchIndividuals(selectedGroupId);
    fetchHouseData(selectedGroupId);
    fetchFamilyData(selectedGroupId);
  }
}, [selectedGroupId]);



// const editRow = (index) => {
//   const contactToEdit = contacts[index];
//   setCurrentContact({ 
//     ...contactToEdit, 
//     group: contactToEdit.group || selectedGroupId, 
//     index 
//   });
//   setEditModalOpen(true);
// };

const editRow = async (index) => {
  setFormErrors({}); 
  const contactToEdit = contacts[index];  // Get the contact based on the index
  const individualId = contactToEdit.id;  // Assuming each contact has an 'id' field

  try {
    const response = await fetch(`${APIURL}/data_collection/api/individual-details/?individual_id=${individualId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`, // Ensure token is properly defined
      },
    });
    
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === "success") {
      const contactToEdit = data.data || {}; // Ensure data structure matches the response
      setCurrentContact({
        ...contactToEdit,
        group: contactToEdit.group || selectedGroupId, // Fallback to selected group if missing
        index,
      });
      setEditModalOpen(true); 
    } else {
      console.error('Failed to fetch individual details');
    }
  } catch (error) {
    console.error("Error fetching individual details:", error);
  }
};

const   updateRow = async () => {
  try {
    const statusMap = {
      "Inactive": 0,
      "Active": 1,
    };  


    console.log(currentContact.eligible_to_vote);
    console.log(Boolean(currentContact.eligible_to_vote));


    let current_household_id = currentContact.household ? currentContact.household.id : null;
 
    const updatedContact = {
      ...currentContact,
      status: statusMap[currentContact.status],
      dob: formatDate(currentContact.dob),
      // eligible_to_vote: currentContact.eligible_to_vote === 'yes',
      //  pension: currentContact.pension === 'yes',
      // household: currentContact.household.id,
      // eligible_to_vote: currentContact.eligible_to_vote === 'yes',
      household: current_household_id,
      // pension: currentContact.pension === 'yes'
     
       eligible_to_vote: Boolean(currentContact.eligible_to_vote),
        pension: Boolean(currentContact.pension),
    // differently_abled:Boolean(currentContact.differently_abled),

    };

    delete updatedContact.age;

    const updateResponse = await fetch(`${APIURL}/data_collection/api/update-individuals/?individual_id=${updatedContact.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(updatedContact)
    });

    const updateData = await updateResponse.json();

    if (updateData.status === 'success') {
     
      const fetchResponse = await fetch(`${APIURL}/data_collection/api/individuals/?group_id=${selectedGroupId}`, {
        method: "GET",
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      });
      fetchHouseData(selectedGroupId);
      fetchFamilyData(selectedGroupId);
      
      const fetchData = await fetchResponse.json();
      
      if (fetchData.status === "success") {
        setContacts(fetchData.data || []);
      } else {
        setContacts([]);
      }

      setEditModalOpen(false);
      alert(updateData.message);
    } else {
      alert(updateData.message);
    }
  } catch (error) {
    console.error('Update error:', error);
    alert('Updation failed');
  }
};
  
  
  
  

const handleChange = (e, setContact) => {
  const { name, value } = e.target;
  setContact(prevState => {
    const newState = { ...prevState };

    if (name === 'dob') {
      newState.dob = value;
      
      // Calculate ageo
      
      const [year, month, day] = value.split('-');
      const dob = new Date(year, month - 1, day);
      const today = new Date();
      let age = today.getFullYear() - dob.getFullYear();
      const monthDiff = today.getMonth() - dob.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      newState.age = age.toString();
    } else if (name === 'age') {
      newState.age = value;
      
      // Calculate DOB
      const today = new Date();
      const dob = new Date(today.getFullYear() - parseInt(value), today.getMonth(), today.getDate());
      const year = dob.getFullYear();
      const month = String(dob.getMonth() + 1).padStart(2, '0');
      const day = String(dob.getDate()).padStart(2, '0');
      newState.dob = `${year}-${month}-${day}`;
    } else if (name === 'eligible_to_vote' || name === 'pension' || name === 'differently_abled') {
      
      newState[name] = value;
    } else {
      newState[name] = value;
    }
    
    return newState;
  });
};




  // const deleteRow = (index) => {
  //   if (window.confirm('Are you sure you want to delete this contact?')) {
  //     const updatedContacts = contacts.filter((_, i) => i !== index);..
  //     setContacts(updatedContacts);
  //   }
  // };




  

  
    const deleteRow = async (individualId) => {
      if (window.confirm('Are you sure you want to delete this contact?')) {
        try {
          const response = await fetch(`${APIURL}/data_collection/api/individuals/delete/?individual_id=${individualId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ id: individualId, confirm:true })
          });
    
          const data = await response.json();
    
          if (data.status === 'success') {
            alert(data.message);
            fetchIndividuals(selectedGroupId);
            fetchHouseData(selectedGroupId);
      fetchFamilyData(selectedGroupId);
           
            setContacts(contacts.filter(contact => contact.id !== individualId));
          } else {
            alert(data.message || 'An error occurred while marking the individual as inactive');
          }
        } catch (error) {
          console.error('Error deleting individual:', error);
          alert('An error occurred while deleting the contact');
        }
      }
    };
 
  
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

 
  

  const addHouseData = async () => {
    const mandatoryFields = ['house_no', 'house_name', 'family_name'];
    const emptyMandatoryFields = mandatoryFields.filter(field => !newHouseData[field]);
    
    if (emptyMandatoryFields.length > 0) {
      alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
      return;
    }
  
    if (!/^[1-9]\d*$/.test(newHouseData.house_no)) {
      alert('House number must be a positive integer');
      return;
    }
  
    try {
      const houseDataToSubmit = {
        ...newHouseData,
        group: selectedGroupId,
        house_no: parseInt(newHouseData.house_no, 10),
        electricity: Boolean(newHouseData.electricity),
        toilet: Boolean(newHouseData.toilet),
        road_access: Boolean(newHouseData.road_access),
        waste_management:newHouseData.waste_management,
        land: newHouseData.land 
      };
  
  
      const response = await fetch(`${APIURL}/data_collection/api/households/?group_id=${selectedGroupId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(houseDataToSubmit)
      });
  
      const data = await response.json();
      
      if (data.status === 'success') {
        alert(data.message || 'New house data added');
        fetchIndividuals(selectedGroupId);
            fetchHouseData(selectedGroupId);
      fetchFamilyData(selectedGroupId);
        setHouseModalOpen(false);
        setNewHouseData(initialHouseState);
        setNewHouseData({
          house_no: '',
          house_name: '',
          family_name: '',
          land: 'No',
          house_status: 'choose an option',
          electricity: false,
          toilet: false,
          road_access: false,
          waste_management: 'No',
          drinking_water_source: 'choose an option',
          ration_card_type: 'APL',
          ration_card_number: ''
        });
      } else {
        alert(data.message || 'Failed to add new house data');
      }
    } catch (error) {
      console.error('Error adding new house data:', error);
      alert('An error occurred while adding the house data');
    }
  };
  const fetchHouseData = async (groupId) => {
    try {
      const response = await fetch(`${APIURL}/data_collection/api/households/?group_id=${groupId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${token}`
        },
      });
      const data = await response.json();
      if (data.status === "success") {
        setHouseData(data.data || []);
      } else {
        setHouseData([]);
      }
    } catch (error) {
      console.error("Error fetching house data:", error);
      setHouseData([]);
    }
  };


  const customStyles = {
    table: {
      style: {
        width: '99%',
        borderCollapse: 'collapse',
        minWidth: '600px',
        backgroundColor: '#E4DD9F85',
        borderRadius: '50px',
        marginLeft: '5px'
      },
    },
    rows: {
      style: {
        minHeight: '50px',
        backgroundColor: '#E4DD9F85',
      },
    },
    headCells: {
      style: {
        paddingLeft: '4px',
        paddingRight: '4px',
        backgroundColor: '#344771',
        border: '2px solid #FFFFFF',
        color: '#ffffff',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        paddingLeft: '4px',
        paddingRight: '4px', 
        border: '2px solid #FFFFFF',
        padding: '8px',
        backgroundColor: '#DCE9F7',
      },
    },
  };
  

  const greenColumnStyle = {
    backgroundColor: '#B9D4EE',
  };
  
  const updateHouseData = async () => {
    const mandatoryFields = ['group', 'house_no', 'house_name', 'family_name'];
    const emptyMandatoryFields = mandatoryFields.filter(field => !currentHouseData[field]);
    
    if (emptyMandatoryFields.length > 0) {
      alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
      return;
    }
  
    try {
      const response = await fetch(`${APIURL}/data_collection/api/households/?household_id=${currentHouseData.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
          house_no: currentHouseData.house_no,
          house_name: currentHouseData.house_name,
          family_name: currentHouseData.family_name,
          land: currentHouseData.land,
          house_status: currentHouseData.house_status,
          drinking_water_source: currentHouseData.drinking_water_source,
          electricity: Boolean(currentHouseData.electricity),
        toilet: Boolean(currentHouseData.toilet),
        road_access: Boolean(currentHouseData.road_access),
        waste_management: currentHouseData.waste_management,
          ration_card_type: currentHouseData.ration_card_type,
          ration_card_number: currentHouseData.ration_card_number
        })
      });
  
      const data = await response.json();
  
      if (data.status === 'success') {
        alert(data.message || 'Household data updated successfully');
        fetchIndividuals(selectedGroupId);
        fetchHouseData(selectedGroupId); 
        fetchFamilyData(selectedGroupId);
        setEditHouseModalOpen(false);
        setCurrentHouseData(null);
      } else {
        alert(data.message || 'Failed to update household data');
      }
    } catch (error) {
      console.error('Error updating household data:', error);
      alert('An error occurred while updating the household data');
    }
  };


  const editHouseRow = (row) => {
    setFormErrors({}); 
    setCurrentHouseData({ ...row });
    setEditHouseModalOpen(true);
  };

 
    
    // const deleteHouseRow = (index) => {
    //   if (window.confirm('Are you sure you want to delete this house data?')) {
    //     const updatedHouseData = houseData.filter((_, i) => i !== index);
    //     setHouseData(updatedHouseData);
    //   }
    // };


   const deleteHouseRow = async (householdId) => {
  if (window.confirm('Are you sure you want to delete this house data?')) {
    try {
      const response = await fetch(`${APIURL}/data_collection/api/households/delete/?household_id=${householdId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ id: householdId, confirm: true })
      });

      const data = await response.json();

      if (data.status === 'success') {
        alert(data.message || 'House data deleted successfully');
        fetchIndividuals(selectedGroupId);
        fetchHouseData(selectedGroupId);
  fetchFamilyData(selectedGroupId);
        setHouseData(houseData.filter(house => house.id !== householdId));
      } else {
        alert(data.message || 'An error occurred while deleting the house data');
      }
    } catch (error) {
      console.error('Error deleting house data:', error);
      alert('An error occurred while deleting the house data');
    }
  }
};




    const addFamilyData = async () => {
      const mandatoryFields = ['family_name'];
      const emptyMandatoryFields = mandatoryFields.filter(field => !newFamilyData[field]);
      
      if (emptyMandatoryFields.length > 0) {
        alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
        return;
      }
    
      try {
        const familyDataToSubmit = {
          ...newFamilyData,
          group: selectedGroupId, 
        };
    
        const response = await fetch(`${APIURL}/data_collection/api/family/?group_id=${selectedGroupId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(familyDataToSubmit)
        });
    
        const data = await response.json();
        
        if (data.status === 'success') {
          alert(data.message || 'New family data added');
          fetchIndividuals(selectedGroupId);
          fetchHouseData(selectedGroupId);
    fetchFamilyData(selectedGroupId);
          setFamilyModalOpen(false);
          setNewHouseData(initialHouseState);
          setNewFamilyData({
            
            family_name: '',
          });
        } else {
          alert(data.message || 'Failed to add new family data');
        }
      } catch (error) {
        console.error('Error adding new family data:', error);
        alert('An error occurred while adding the family data');
      }
    };
    

    const fetchFamilyData = async (groupId) => {
      try {
        const response = await fetch(`${APIURL}/data_collection/api/family/?group_id=${groupId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
          },
        });
        const data = await response.json();
        if (data.status === "success") {
          setFamilyData(data.data || []);
        } else {
          setFamilyData([]);
        }
      } catch (error) {
        console.error("Error fetching family data:", error);
        setFamilyData([]);
      }
    };


    const editFamilyRow = (index) => {
      const familyToEdit = familyData[index];
      setFormErrors({}); 
      setCurrentFamilyData({ 
        ...familyToEdit,
        group: selectedGroup,
        index 
      });
      setEditFamilyModalOpen(true);
    };
    
    const updateFamilyData = async () => {
      const mandatoryFields = ['family_name'];
      const emptyMandatoryFields = mandatoryFields.filter(field => !currentFamilyData[field]);
      
      if (emptyMandatoryFields.length > 0) {
        alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
        return;
      }
      
      if (!currentFamilyData.id) {
        alert('Family ID is missing. Cannot update.');
        return;
      }
    
      try {
        const response = await fetch(`${APIURL}/data_collection/api/family/?family_id=${currentFamilyData.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify({
            family_name: currentFamilyData.family_name,
           
          })
        });
    
        const data = await response.json();
        
        if (data.status === 'success') {
          alert(data.message || 'Family data updated successfully');
          // Update the local state
          const updatedFamilyData = familyData.map(family => 
            family.id === currentFamilyData.id ? {...family, ...data.data.data} : family
          );
          setFamilyData(updatedFamilyData);
          fetchHouseData(selectedGroupId); 
          setEditFamilyModalOpen(false);
          setCurrentFamilyData(null);
        } else {
          alert(data.message || 'Failed to update family data');
        }
      } catch (error) {
        console.error('Error updating family data:', error);
        alert('An error occurred while updating the family data');
      }
    };


    // const deleteFamilyRow = (index) => {
    //   if (window.confirm('Are you sure you want to delete this family data?')) {
    //     const updatedFamilyData = familyData.filter((_, i) => i !== index);
    //     setFamilyData(updatedFamilyData);
    //   }
    // };



      const deleteFamilyRow = async (familyId) => {
  if (window.confirm('Are you sure you want to delete this Family?')) {
    try {
      const response = await fetch(`${APIURL}/data_collection/api/family/delete/?family_id=${familyId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ id: familyId, confirm: true })
      });

      const data = await response.json();

      if (data.status === 'success') {
        alert(data.message || 'Family deleted successfully');
        fetchIndividuals(selectedGroupId);
        fetchHouseData(selectedGroupId);
  fetchFamilyData(selectedGroupId);
        setFamilyData(familyData.filter(family => family.id !== familyId));
      } else {
        alert(data.message || 'An error occurred while deleting the Family data');
      }
    } catch (error) {
      console.error('Error deleting Family data:', error);
      alert('An error occurred while deleting the Family data');
    }
  }
};

    // async function handleFileUpload() {
    //   const input = document.createElement('input');
    //   input.type = 'file';
    //   input.accept = '.xlsx,.xls,.csv';
    
    //   input.onchange = async (event) => {
    //     const file = event.target.files[0];
    //     if (!file) {
    //       console.error('No file selected');
    //       return;
    //     }
    
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('group_id', selectedGroupId); // Using the selectedGroupId from the component's state
    
    //     try {
    //       const response = await axios.post(`${APIURL}/data_collection/api/upload-individuals/`, formData, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           'Authorization': `Token ${token}` // Using the token from localStorage
    //         }
    //       });
    
    //       if (response.data.status === 'success') {
    //         console.log(response.data.message);
    //         alert(response.data.message); // Show success message to the user
    //         // Optionally, you can update the contacts state here if needed
    //         fetchIndividuals(selectedGroupId); // Refresh the individuals list
    //       } else {
    //         console.error('Upload failed:', response.data.message);
    //         alert(`Upload failed: ${response.data.message}`); // Show error message to the user
    //       }
    //     } catch (error) {
    //       console.error('Error uploading file:', error);
    //       alert('Error uploading file. Please try again.'); // Show general error message
    //     }
    //   };
    
    //   input.click();
    // }

 
  
    const handleFormTabChange = (event, newIndex) => {
      setFormTabIndex(newIndex);
    };
  

    useEffect(() => {
      if (!modalOpen && !editModalOpen) {
        setFormTabIndex(0);
      }
    }, [modalOpen, editModalOpen]);

  
    const renderFormFields = (fields, contactData, setContactData, isEditMode = false) => {
      if (!contactData) return null;
    
      return fields.map((field) => (
        <Grid item xs={6} key={field}>
          <Typography variant="body1" className="field-label">
            {field === 'sl_no' ? 'Sl.No (as per voters list)' :
             field === 'polling_booth_number' ? 'Polling Booth No.' :
             field === 'polling_booth_name' ? 'Polling Booth Name' :
             field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            {['name', 'gender', 'house_no', 'house_name', 'polling_booth_number', 'polling_booth_name'].includes(field) && 
             <span style={{ color: 'red' }}> *</span>}
          </Typography>
          {field === 'expatriate' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {EXPATRIATE_STATUS_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : field === 'name' || field === 'guardian_name'   || field === 'guardian_relation' || field ==='occupation' ? (
            <TextField
              name={field}
              type="text"
              fullWidth
              variant="outlined"
              value={contactData[field]}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z\s]*$/.test(value)) {
                  handleChange(e, setContactData);
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: ''
                  }));
                } else {
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only alphabetic characters and spaces.`
                  }));
                }
              }}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              inputProps={{ pattern: "[a-zA-Z\s]*" }}
            />
          ) : field === 'dob' ? (
            <TextField
              name={field}
              type="date"
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              InputLabelProps={{ shrink: true }}
            />
          ) : field === 'age' || field === 'sl_no' || field === 'house_no' || field === 'phone'  || field === 'aadhaar_number' || field === 'polling_booth_number' ? (
            <TextField
              name={field}polling_booth_number
              type="text"
              fullWidth
              variant="outlined"
              value={contactData[field]}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleChange(e, setContactData);
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: ''
                  }));
                } else {
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only digits.`
                  }));
                }
              }}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              inputProps={{ 
                pattern: "\\d*",
                readOnly: contactData.verified && isEditMode && (field === 'house_no' || field === 'house_name')
              }}
            />
          ) : field === 'group' ? (
            <TextField
              name={field}
              fullWidth
              variant="outlined"
              value={selectedGroup}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              InputProps={{
                readOnly: true,
              }}
              required
            />
          ) : field === 'gender' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              required={['name', 'gender', 'house_no', 'house_name'].includes(field)}
            >
              {GENDER_CHOICES.map((choice) => (
                <MenuItem key={choice} value={Array.isArray(choice) ? choice[0] : choice}>
                  {Array.isArray(choice) ? choice[1] : choice}
                </MenuItem>
              ))}
            </Select>
          ) : field === 'marital_status' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              required={['name', 'gender', 'house_no', 'house_name'].includes(field)}
            >
              {MARITAL_STATUS_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : ['pension'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] ? 'true' : 'false'}
              onChange={(e) => {
                const values = e.target.value === 'true';
                setContactData(prevState => ({
                  ...prevState,
                  pension: values
                 
                }));
              }}
              className="form-field"
            >
              <MenuItem value="false">No</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
            </Select>
          
        ):['eligible_to_vote'].includes(field) ? (
          <Select
            name={field}
            fullWidth
            variant="outlined"
            value={contactData[field] ? 'true' : 'false'}
            onChange={(e) => {
              const value = e.target.value === 'true';
              setContactData(prevState => ({
                ...prevState,
                eligible_to_vote: value
               
              }));
            }}
            className="form-field"
          >
            <MenuItem value="false">No</MenuItem>
            <MenuItem value="true">Yes</MenuItem>
          </Select>
        ): field === 'differently_abled' ? (
          <Select
            name={field}
            fullWidth
            variant="outlined"
            value={contactData[field] || ''}
            onChange={(e) => handleChange(e, setContactData)}
            className="form-field"
            error={!!formErrors[field]}
            required={['name', 'gender', 'house_no', 'house_name'].includes(field)}
          >
            {DIFFERENTLY_ABLED_CHOICES.map((choice) => (
              <MenuItem key={choice} value={choice}>{choice}</MenuItem>
            ))}
          </Select>
      // ): ['differently_abled'].includes(field) ? (
      //       <Select
      //         name={field}
      //         fullWidth
      //         variant="outlined"
      //         value={contactData[field] ? 'true' : 'false'}
      //         onChange={(e) => {
      //           const valuess = e.target.value === 'true';
      //           setContactData(prevState => ({
      //             ...prevState,
      //             differently_abled: valuess
                 
      //           }));
      //         }}
      //         className="form-field"
      //       >
      //         <MenuItem value="false">No</MenuItem>
      //         <MenuItem value="true">Yes</MenuItem>
      //       </Select>
          ) : field === 'education' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {EDUCATION_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : field === 'vehicles' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {VEHICLE_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : field === 'religion' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {RELIGION_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              name={field}
              type={
                field === 'email' ? 'email' : 
                field === 'sl_no' || field === 'house_no' || field === 'aadhaar_number' ? 'number' : 
                'text'
              }
              inputProps={{ 
                pattern: "\\d*",
                readOnly: contactData.verified && isEditMode && (field === 'house_no' || field === 'house_name')
              }}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              required={['group', 'name', 'gender', 'house_no', 'house_name'].includes(field)}
            />
          )}
        </Grid>
      ));
    };


  
  return (
    <div>
     <Box sx={{ 
      borderColor: 'divider', 
      backgroundColor: '#E6E6FA', 
      borderRadius: '8px',
      marginBottom: '30px',
      overflowX: 'auto', 
      display: 'flex'
    }}>
      <Tabs 
        value={tabIndex} 
        onChange={handleTabChange} 
        aria-label="dashboard tabs" 
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#034694', 
          },
        }}
      >
    
        <Tab 
          icon={<PersonIcon />} 
          iconPosition="start" 
          label="INDIVIDUAL DATA" 
          sx={{
            color: tabIndex === 0 ? '#00ff00' : '',
            '&.Mui-selected': {
              color: '#034694',
            },
          }}
        />
        <Tab 
          icon={<HouseIcon />} 
          iconPosition="start" 
          label="HOUSEHOLD DATA" 
          sx={{
            color: tabIndex === 1 ? '#00ff00' : '',
            '&.Mui-selected': {
              color: '#034694',
            },
          }}
        />
        <Tab 
          icon={<GroupsIcon />} 
          iconPosition="start" 
          label="FAMILY DATA" 
          sx={{
            color: tabIndex === 2 ? '#00ff00' : '',
            '&.Mui-selected': {
              color: '#034694',
            },
          }}
        />
      </Tabs>
    </Box>

                {tabIndex === 0 && (
                    <div>
                
                    <ButtonContainer>
  {/* <AddButton onClick={handleFileUpload}>
    Add File &nbsp; <b>+</b>
  </AddButton>&nbsp;&nbsp; */}
  <AddButton onClick={openModal}>
    Add Data &nbsp; <b>+</b>
  </AddButton>
</ButtonContainer>
<DataTable
  columns={[
    {
      name: 'Sl.No',
      selector: row => row.sl_no,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Guardian Name',
      selector: row => row.guardian_name,
      sortable: true,
    },
    {
      name: 'Gender',
      selector: row => row.gender,
      sortable: true,
    },
    {
      name: 'Booth No',
      selector: row => `${row.polling_booth_number}`,
      sortable: true,
    },
    {
      name: 'House No',
      selector: row => `${row.house_no}`,
      sortable: true,
      style: greenColumnStyle,
    },
    {
      name: 'House Name',
      selector: row => `${row.house_name}`,
      sortable: true,
      style: greenColumnStyle,
    },
    {
  name: 'Verify',
  cell: (row) => (
    <VerifyIcon onClick={() => toggleVerification(row)}>
      {row.verified ? (
        <FaCheckCircle style={{ color: 'green', cursor: 'pointer' }} size={20} />
      ) : (
        <FaTimesCircle style={{ color: '#787878', cursor: 'pointer' }} size={20} />
      )}
    </VerifyIcon>
  ),
  width: '60px',
  style: greenColumnStyle,
},


    {
      name: 'Age',
      selector: row => row.age,
      sortable: true,
    },
    {
      name: 'Epic No',
      selector: row => row.epic_no,
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row, index) => (
        <EditIcon onClick={() => editRow(index)}>
          <FaEdit style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
        </EditIcon>
      ),
      width: '60px',
    },
    {
      name: 'Delete',
      cell: (row, index) => (
        <DeleteIcon onClick={() => deleteRow(row.id)}>
          <FaTrash style={{ color: '#1d1160', cursor: 'pointer' }} size={18} />
        </DeleteIcon>
      ),
      width: '60px',
    },
  ]}
  data={contacts}
  highlightOnHover
  responsive
  customStyles={customStyles}
/>


<Dialog open={verificationModalOpen} onClose={() => setVerificationModalOpen(false)}>
  <DialogTitle>Verify Individual</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      {['house_no', 'house_name'].map((field) => (
        <Grid item xs={6} key={field}>
          <Typography variant="body1" className="field-label">
            {field.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
            <span style={{ color: 'red' }}> *</span>
          </Typography>
          <TextField
            name={field}
            type="text"
            fullWidth
            variant="outlined"
            value={verificationData[field] || ''}
            onChange={(e) => setVerificationData({ ...verificationData, [field]: e.target.value })}
            className="form-field"
            required
          />
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setVerificationModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px', marginRight: '10px' }}>
                    Cancel
                  </Button>
    <Button onClick={handleVerification} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
                    Verify
                  </Button>
  </DialogActions>
</Dialog>
{/*  <Button onClick={() => setModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px', marginRight: '10px' }}>
                    Cancel
                  </Button>
                  <Button onClick={addRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
                    Submit
                  </Button> */}

            
<Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Add Individual Data - {capitalizeFirstLetter(selectedGroup)} </DialogTitle>
        <DialogContent>
          <Accordion expanded={expanded === 'personal'} onChange={handleAccordionChange('personal')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <b style={{ color: '#06368B' }}>Personal</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['name','house_no','polling_booth_number','polling_booth_name', 'house_name', 'sl_no', 'guardian_name', 'guardian_relation', 'dob', 'age', 'gender', 'epic_no'], newContact, setNewContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'identity'} onChange={handleAccordionChange('identity')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              <b style={{ color: '#06368B' }}>Identity & Social Status</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['pension', 'aadhaar_number', 'email', 'phone', 'eligible_to_vote', 'education', 'occupation', 'marital_status', 'vehicles', 'differently_abled', 'chronic_disease', 'expatriate'], newContact, setNewContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'political'} onChange={handleAccordionChange('political')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <b style={{ color: '#06368B' }}>Political & Religious</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['religion', 'caste', 'sub_caste', 'religious_organizations', 'social_organizations', 'political_affiliation_party', 'political_affiliation_status'], newContact, setNewContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button onClick={() => setModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: isSmallScreen ? '150px' : '200px', marginRight: '10px', marginBottom: '10px', marginLeft: '160px' }}>
            Cancel
          </Button>
          <Button onClick={addRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: isSmallScreen ? '150px' : '200px', marginBottom: '10px' }}>
            Submit
          </Button>
        </Grid>
      </Dialog>
     

      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
  <DialogTitle>Edit Individual Data - {capitalizeFirstLetter(selectedGroup)} </DialogTitle>
  <DialogContent>
    <Accordion expanded={expanded === 'personal'} onChange={handleAccordionChange('personal')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
        <b style={{ color: '#06368B' }}> Personal</b>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {renderFormFields(['name','house_no','polling_booth_number','polling_booth_name', 'house_name', 'sl_no', 'guardian_name', 'guardian_relation', 'dob', 'age', 'gender', 'epic_no'], currentContact, setCurrentContact, true)}
        </Grid>
      </AccordionDetails>
    </Accordion>

    <Accordion expanded={expanded === 'identity'} onChange={handleAccordionChange('identity')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5-content" id="panel5-header">
        <b style={{ color: '#06368B' }}>Identity & Social Status</b>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {renderFormFields(['pension', 'aadhaar_number', 'email', 'phone', 'eligible_to_vote', 'education', 'occupation', 'marital_status', 'vehicles', 'differently_abled', 'chronic_disease', 'expatriate'], currentContact, setCurrentContact, true)}
        </Grid>
      </AccordionDetails>
    </Accordion>

    <Accordion expanded={expanded === 'political'} onChange={handleAccordionChange('political')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6-content" id="panel6-header">
        <b style={{ color: '#06368B' }}>Political & Religious</b>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {renderFormFields(['religion', 'caste', 'sub_caste', 'religious_organizations', 'social_organizations', 'political_affiliation_party', 'political_affiliation_status'], currentContact, setCurrentContact, true)}
        </Grid>
      </AccordionDetails>
    </Accordion>
  </DialogContent>
  <Grid item xs={12} sx={{ mt: 2 }}>
    <Button onClick={() => setEditModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: isSmallScreen ? '150px' : '200px', marginRight: '10px', marginBottom: '10px', marginLeft: '160px' }}>
      Cancel
    </Button>
    <Button onClick={updateRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: isSmallScreen ? '150px' : '200px', marginBottom: '10px' }}>
      Update
    </Button>
  </Grid>
</Dialog>

                  </div>
                    
                  )}
                  {tabIndex === 1 && (
                    <div>
        <ButtonContainer>
        <AddButton  onClick={openHouseModal}> Add Data &nbsp; <b>+</b> </AddButton>
      </ButtonContainer>
      <DataTable
  columns={[
   
   
    {
      name: 'House No',
      selector: row => row.house_no,
      sortable: true,
    },
    {
      name: 'House Name',
      selector: row => row.house_name,
      sortable: true,
    },
    {
      name: 'Total Member',
      selector: row => row.total_house_members,
      sortable: true,
    },
    {
      name: 'Total Voters',
      selector: row => row.total_house_voters,
      sortable: true,
    },
    {
      name: 'Family Name',
      selector: row => row.family_name ,
      sortable: true,
      // style: greenColumnStyle,
    },
    // {
    //   name: 'Verify',
    //   cell: (row) => (
    //     <VerifyIcon onClick={() => toggleVerificationOne(row.id)}>
    //       {row.verified ? (
    //         <FaCheckCircle style={{ color: 'green', cursor: 'pointer' }} size={20} />
    //       ) : (
    //         <FaTimesCircle style={{ color: '#787878', cursor: 'pointer' }} size={20} />
    //       )}
    //     </VerifyIcon>
    //   ),
    //   width: '60px',
    //   style: greenColumnStyle,
    // },
    {
      name: 'Ration Card Type',
      selector: row => row.ration_card_type,
      sortable: true,
    },
    {
            name: 'Edit',
            cell: (row) => (
              <EditIcon onClick={() => editHouseRow(row)}>
                <FaEdit style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
              </EditIcon>
            ),
            width: '60px',
          },
    {
      name: 'Delete',
      cell: (row, index) => (
        <DeleteIcon onClick={() => deleteHouseRow(row.id)}>
          <FaTrash style={{ color: '#1d1160', cursor: 'pointer' }} size={18} />
        </DeleteIcon>
      ),
      width: '60px',
    },
   
  ]}
  data={houseData}
  highlightOnHover
  responsive
  customStyles={customStyles}
/>


 {/* Add House Data Dialog */}
 <Dialog open={houseModalOpen} onClose={() => setHouseModalOpen(false)}>
        <DialogTitle>Add House Data - {capitalizeFirstLetter(selectedGroup)} </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(newHouseData).map((field) => (
              <Grid item xs={6} key={field}>
                <Typography variant="body1" className="field-label">
                  {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  {['house_no', 'house_name', 'family_name'].includes(field) && 
                    <span style={{ color: 'red' }}> *</span>
                  }
                </Typography>
                {/* {field === 'group' ? (
  <TextField
    name={field}
    fullWidth
    variant="outlined"
    value={newHouseData[field]}
    className="form-field"
    InputProps={{
      readOnly: true,
    }}
    required
  />
) :  */}
 
 {field === 'ration_card_type' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={newHouseData[field]}
                    onChange={(e) => handleChange(e, setNewHouseData)}
                    className="form-field"
                  >
                    {RATION_CHOICES.map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                ) : ['toilet', 'electricity', 'road_access'].includes(field) ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={newHouseData[field] ? 'true' : 'false'}
                    onChange={(e) => handleChange(e, setNewHouseData, true)}
                    className="form-field"
                  >
                    <MenuItem value="false">No</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                  </Select>
                ) : ['land', 'waste_management'].includes(field) ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={newHouseData[field]}
                    onChange={(e) => handleChange(e, setNewHouseData)}
                    className="form-field"
                  >
                    {BOOLEAN_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice}>
                        {choice.charAt(0).toUpperCase() + choice.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
              ) :field === 'drinking_water_source' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={newHouseData[field]}
                    onChange={(e) => handleChange(e, setNewHouseData)}
                    className="form-field"
                  >
                    {WATER_SOURCE_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice.toLowerCase()}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                ) : field === 'house_no' || field === 'ration_card_number' ? (
                  <TextField
                    name={field}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={newHouseData[field]} 
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        handleChange(e, setNewHouseData); // Update the correct state
                        // Remove error message if input is valid
                        setFormErrors(prevErrors => ({
                          ...prevErrors,
                          [field]: ''
                        }));
                      } else {
                        setFormErrors(prevErrors => ({
                          ...prevErrors,
                          [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only digits.`
                        }));
                      }
                    }}
                    className="form-field"
                    error={!!formErrors[field]}
                    helperText={formErrors[field]}
                    required={['house_no', 'house_name', 'family_name'].includes(field)}
                    inputProps={{ pattern: "\\d*" }}
                  />
                
                
              ): field === 'family_name'  ? (
                <TextField
                  name={field}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={newContact[field]}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(value)) {
                      handleChange(e, setNewHouseData);
                      // Remove error message if input is valid
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: ''
                      }));
                    } else {
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only alphabetic characters and spaces.`
                      }));
                    }
                  }}
                  className="form-field"
                  error={!!formErrors[field]}
                  helperText={formErrors[field]}
                  required={['house_no', 'house_name', 'family_name'].includes(field)}
                  inputProps={{ pattern: "[a-zA-Z\s]*" }}
                />
              
            ): field === 'house_status' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={newHouseData[field]}
                    onChange={(e) => handleChange(e, setNewHouseData)}
                    className="form-field"
                  >
                    {HOUSE_STATUS_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice.toLowerCase()}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
  name={field}
  type={field === 'house_no' || field === 'total_member' || field === 'total_voters' || field === 'ration_card_number' ? 'number' : 'text'}
  fullWidth
  variant="outlined"
  value={newHouseData[field]}
  onChange={(e) => handleChange(e, setNewHouseData)}
  className="form-field"
  InputProps={
    (field === 'house_no' || field === 'ration_card_number') 
    ? { inputProps: { min: 0 } } 
    : {}
  }
  required={['house_no', 'house_name', 'family_name'].includes(field)}
/>
                )}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHouseModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Cancel
          </Button>
          <Button onClick={addHouseData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>


  {/* Edit House Data Dialog */}
  <Dialog open={editHouseModalOpen} onClose={() => setEditHouseModalOpen(false)}>
  <DialogTitle>Edit House Data - {capitalizeFirstLetter(selectedGroup)} </DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      {currentHouseData && Object.keys(newHouseData).map((field) => (
        <Grid item xs={6} key={field}>
          <Typography variant="body1" className="field-label">
            {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            {['house_no', 'house_name', 'family_name'].includes(field) && 
              <span style={{ color: 'red' }}> *</span>
            }
          </Typography>
          
          {/* {field === 'group' ? (
                <TextField
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={selectedGroup}
                  className="form-field"
                  InputProps={{
                    readOnly: true,
                  }}
                  required
                />
              ) :  */}
              
              {field === 'ration_card_type' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={currentHouseData[field]}
              onChange={(e) => handleChange(e, setCurrentHouseData)}
              className="form-field"
            >
              {RATION_CHOICES.map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          ) : ['toilet', 'electricity', 'road_access'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={currentHouseData[field] ? 'true' : 'false'}
              onChange={(e) => handleChange(e, setCurrentHouseData, true)}
              className="form-field"
            >
              <MenuItem value="false">No</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
            </Select>
          ) : ['land', 'waste_management'].includes(field) ? (
              <Select
                name={field}
                fullWidth
                variant="outlined"
                value={currentHouseData[field]}
                onChange={(e) => handleChange(e, setCurrentHouseData)}
                className="form-field"
              >
                {BOOLEAN_CHOICES.map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {choice.charAt(0).toUpperCase() + choice.slice(1)}
                  </MenuItem>
                ))}
              </Select>
          ) : field === 'drinking_water_source' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={currentHouseData[field]}
              onChange={(e) => handleChange(e, setCurrentHouseData)}
              className="form-field"
            >
              {WATER_SOURCE_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice.toLowerCase()}>
                  {choice}
                </MenuItem>
              ))}
            </Select>
          ) :field === 'family_name'  ? (
            <TextField
              name={field}
              type="text"
              fullWidth
              variant="outlined"
              value={currentHouseData[field]}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z\s]*$/.test(value)) {
                  handleChange(e, setCurrentHouseData);
                  // Remove error message if input is valid
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: ''
                  }));
                } else {
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only alphabetic characters and spaces.`
                  }));
                }
              }}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              required={['house_no', 'house_name', 'family_name'].includes(field)}
              inputProps={{ pattern: "[a-zA-Z\s]*" }}
            />
          
        ):field === 'house_status' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={currentHouseData[field]}
              onChange={(e) => handleChange(e, setCurrentHouseData)}
              className="form-field"
            >
              {HOUSE_STATUS_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice.toLowerCase()}>
                  {choice}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              name={field}
              type={field === 'house_no' || field === 'total_member' || field === 'total_voters' || field === 'ration_card_number' ? 'number' : 'text'}
              fullWidth
              variant="outlined"
              value={currentHouseData[field]}
              onChange={(e) => handleChange(e, setCurrentHouseData)}
              className="form-field"
              InputProps={
                (field === 'house_no' || field === 'ration_card_number') 
                ? { inputProps: { min: 0 } } 
                : {}
              }
              required={['house_no', 'house_name', 'family_name'].includes(field)}
            />
          )}
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setEditHouseModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Cancel
    </Button>
    <Button onClick={updateHouseData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
      Update
    </Button>
  </DialogActions>
</Dialog>
      </div>
          )}
         {tabIndex === 2 && (
  <div>
    <ButtonContainer>
      <AddButton onClick={openFamilyModal}> Add Data &nbsp; <b>+</b> </AddButton>
    </ButtonContainer>
    <DataTable
  columns={[
   
    {
      name: 'Family Name', 
      selector: row => row.family_name,
      sortable: true,
    },
    {
      name: 'Total Households',
      selector: row => row.total_family_households,
      sortable: true,
    },
    {
      name: 'Total Members',
      selector: row => row.total_family_members,
      sortable: true,
    },
    {
      name: 'Total Voters',
      selector: row => row.total_family_voters,
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row, index) => (
        <FaEdit 
          style={{ color: '#1d1160', cursor: 'pointer' }} 
          size={20} 
          onClick={() => editFamilyRow(index)}
        />
      ),
      width: '60px',
    },
    {
      name: 'Delete',
      cell: (row, index) => (
        <FaTrash 
          style={{ color: '#1d1160', cursor: 'pointer' }} 
          size={18} 
          onClick={() => deleteFamilyRow(row.id)}
        />
      ),
      width: '60px',
    },
   
  ]}
  data={familyData}
  highlightOnHover
  responsive
  customStyles={customStyles}
/>


    {/* Add Family Data Dialog */}
    <Dialog open={familyModalOpen} onClose={() => setFamilyModalOpen(false)}>
        <DialogTitle>Add Family Data - {capitalizeFirstLetter(selectedGroup)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(newFamilyData).map((field) => (
              <Grid item xs={12} key={field}>
                <Typography variant="body1">
                  {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  {field === 'family_name' && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                <TextField
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newFamilyData[field]}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(value)) {
                      handleChange(e, setNewFamilyData);
                      // Remove error message if input is valid
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: ''
                      }));
                    } else {
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only alphabetic characters and spaces.`
                      }));
                    }
                  }}
                  className="form-field"
                  error={!!formErrors[field]}
                  helperText={formErrors[field]}
                  required={['family_name'].includes(field)}
                  inputProps={{ pattern: "[a-zA-Z\s]*" }}
                  // InputProps={{
                  //   readOnly: field === 'group',
                  // }}
                />

                
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFamilyModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Cancel
          </Button>
          <Button onClick={addFamilyData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Family Data Dialog */}
      <Dialog open={editFamilyModalOpen} onClose={() => setEditFamilyModalOpen(false)}>
        <DialogTitle>Edit Family Data - {capitalizeFirstLetter(selectedGroup)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
          {currentFamilyData && Object.keys(currentFamilyData)
            .filter(key =>
              key !== 'index' &&
              key !== 'id' &&
              key !== 'family_name_variations' &&
              key !== 'total_family_households' &&
              key !== 'total_family_members' &&
              key !== 'total_family_voters'&&
              key !== 'total_family_national_expatriates'&&
              key !== 'total_family_international_expatriates'&&
              key !== 'status'&&
              key !== 'added_by'&&
              key !== 'edited_by'&&
              key !== 'group'
              
            ).map((field) => (
              <Grid item xs={12} key={field}>
                <Typography variant="body1">
                  {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  {field === 'family_name' && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                <TextField
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={currentFamilyData[field]}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(value)) {
                      handleChange(e, setCurrentFamilyData);
                      // Remove error message if input is valid
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: ''
                      }));
                    } else {
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only alphabetic characters and spaces.`
                      }));
                    }
                  }}
                  className="form-field"
                  error={!!formErrors[field]}
                  helperText={formErrors[field]}
                  required={['family_name'].includes(field)}
                  inputProps={{ pattern: "[a-zA-Z\s]*" }}
                  // InputProps={{
                  //   readOnly: field === 'group',
                  // }}
                />

                
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditFamilyModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Cancel
          </Button>
          <Button onClick={updateFamilyData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
  </div>
)}
        </div>        
  );
}


const DeleteIcon = styled.span`
  cursor: pointer;
  margin-left: 10px;
`;
// const TableContainer = styled.div`
//   width: 100%;
//   overflow-x: auto;
//   background-color:#1877F21A;
//   border-radius:15px;
// ;
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   min-width: 600px;
// `;

const ButtonContainer = styled.div`
  background-color: #6F89B7;
  padding: 13px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
   margin-bottom: 2px;
`;

// const TableCell = styled.td`
//   border: 4px solid #FFFFFF;
//   padding: 16px;
// `;

// const TableRow = styled.tr`
  
//     background-color: #1877F21A;


// `;

const EditIcon = styled.span`
  cursor: pointer;
`;


const AddButton = styled.button`
  padding: 10px 30px;
  background-color: #FFFFFF;
  color: #133E88;
  border: none;
  border-radius: 34px;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #cccccc;
  }
`;


// const FileButton = styled.button`
//   padding: 10px 30px;
//   background-color: #FFFFFF;
//   color: #133E88;
//   border: none;
//   border-radius: 34px;
//   font-weight: 700;
//   font-size: 18px;
//   cursor: pointer;
//   margin-right: 10px;
//   &:hover {
//     background-color: #cccccc;
//   }
// `;


export default SurveysTab;