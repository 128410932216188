import logo from './logo.svg';
import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import LoginPage from './pages/LoginPage';
// import RegisterPage from './pages/RegisterPage';
import DashboardPage from './pages/DashboardPage';
import SecondDashboard from './pages/SecondDashboard';
import LoginPage from './pages/LoginPage';
import DummyPage from './pages/Dummy';
import OTPPage from './pages/OTPPage';
import OTPVerifyPage from './pages/OTPVerifyPage';
// import UserManagementPage from './pages/UserManagementPage';

function App() {
  return (
    <Router>
      <div className="App">
      <Routes>
          <Route path="/" element={<LoginPage />} />
          {/* <Route exact path="/" component={LoginPage} /> */}
          {/* <Route path="/register" component={RegisterPage} /> */}
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/seconddashboard" element={<SecondDashboard />} />
          <Route path="/dummy" element={<DummyPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/otp" element={<OTPPage />} />
          <Route path="/verify" element={<OTPVerifyPage />} />
          {/* <Route path="/user-management" component={UserManagementPage} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
